import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Table, useFetch } from "@4uhub/lib4uhub";
import { Box, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import MainApi from "../../../../services/mainApi.service";
import { memo, useCallback, useMemo } from "react";
import { useSalesContract } from "../../../../store/contexts/SalesContractContext";
import { ISalesContractPayer } from "../../../../models/sales-contract-payer";
import {
  approveSalesContractPayer,
  inactivateSalesContractPayer,
  reactivateSalesContractPayer,
  suspendSalesContractPayer,
} from "../../../../services/salesContractPayer.service";
import SituationChip from "./SituationChip";
const translationsPath = "page.4uSalesContract.registers.salesContractPayer.";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesContractPayer/";
const service = new MainApi<ISalesContractPayer>(serviceURL);

export type TRowClick = GridRowParams<ISalesContractPayer>;

export const SalesContractPayerPage = memo(() => {
  const { onOpen, selectPropId, onRefresh, selectTabName, refresh } =
    useSalesContract();

  const { sendRequest: approve } = useFetch(approveSalesContractPayer);
  const { sendRequest: inactivate } = useFetch(inactivateSalesContractPayer);
  const { sendRequest: reactivate } = useFetch(reactivateSalesContractPayer);
  const { sendRequest: suspend } = useFetch(suspendSalesContractPayer);

  const { id } = useParams();
  const { t } = useTranslation();
  const translate = (field: string) => t(translationsPath + field);

  const columns: GridColDef<ISalesContractPayer>[] = [
    {
      field: "code",
      maxWidth: 80,
      headerName: translate("code"),
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "payer",
      headerName: translate("payer"),
      renderCell: (params) => {
        const payer = params.row.individual
          ? params.row.individual.individualName
          : params.row.legalEntity?.corporateName;

        return <Typography variant="body2">{payer}</Typography>;
      },
    },
    {
      field: "payerType",
      headerName: translate("payerType"),
      renderCell: (params) => {
        const type = params.row.individual ? "PF" : "PJ";

        return <Typography variant="body2">{type}</Typography>;
      },
    },
    {
      field: "payerTitle",
      headerName: translate("title_payer"),
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "integrationId",
      headerName: translate("integration_code"),
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "address_type",
      headerName: translate("address"),
      renderCell: (params) => {
        return (
          <Typography variant="body2">{params.row.addressType.name}</Typography>
        );
      },
    },
    {
      field: "situation",
      maxWidth: 100,
      headerName: translate("situation"),
      renderCell: ({ value }) => (
        <SituationChip name={value?.name} code={value?.code} />
      ),
    },
  ];

  const handleAdd = () => {
    onOpen();
    selectTabName("payer");
  };

  const rowClickHandler = useCallback(
    (params: GridRowParams<ISalesContractPayer>) => {
      selectPropId(params.row.id);
      onOpen();
      selectTabName("payer");
    },
    [selectPropId, onOpen, selectTabName]
  );

  const onApproveSalesContractPayer = useCallback(
    async (id: string) => {
      const { success } = await approve(id);
      if (success) {
        onRefresh();
      }
    },
    [approve, onRefresh]
  );
  const onInactivateSalesContractPayer = useCallback(
    async (id: string) => {
      const { success } = await inactivate(id);
      if (success) {
        onRefresh();
      }
    },
    [inactivate, onRefresh]
  );

  const onReactivationSalesContractPayer = useCallback(
    async (id: string) => {
      const { success } = await reactivate(id);
      if (success) {
        onRefresh();
      }
    },
    [reactivate, onRefresh]
  );

  const onSuspensionSalesContractPayer = useCallback(
    async (id: string) => {
      const { success } = await suspend(id);
      if (success) {
        onRefresh();
      }
    },
    [suspend, onRefresh]
  );

  const handleOpenPayerFinantial = useCallback(
    (params: GridRowParams<ISalesContractPayer>) => {
      selectPropId(params.row.id);
      onOpen();
      selectTabName("payerFinantial");
    },
    [selectPropId, onOpen, selectTabName]
  );

  const props = useMemo(() => {
    return { SalesContractId: id, refresh: refresh };
  }, [id, refresh]);

  return (
    <>
      <Box sx={{ height: 600, width: "100%" }}>
        <Box height={600}>
          <Table<ISalesContractPayer>
            searchInput
            showDefaultMacroFilters={false}
            columns={columns}
            service={service}
            onAdd={handleAdd}
            onRowClick={rowClickHandler}
            serviceProps={props}
            title={translate("title")}
            defaultPageSize={20}
            pageSizeOptions={[5, 10, 20]}
            addButtonLabel={translate("add")}
            searchInputPlaceHolder={translate("search")}
            rowActions={(params) => [
              {
                action: (params) => {
                  handleOpenPayerFinantial(params);
                },
                icon: <FormatListBulletedIcon fontSize="small" />,
                text: `${t(translationsPath + "payer_finantial")}`,
              },
              params.row.situation.code === "1" &&
              params.row.approvalDate === null
                ? {
                    action: (params) => {
                      onApproveSalesContractPayer(params.row.id);
                    },
                    icon: <CheckCircleIcon fontSize="small" />,
                    text: `${t(translationsPath + "approve")}`,
                  }
                : null,
              params.row.inactivationDate === null &&
              params.row.situation.code === "1"
                ? {
                    action: (params) => {
                      onInactivateSalesContractPayer(params.row.id);
                    },
                    icon: <DoDisturbOnIcon fontSize="small" />,
                    text: `${t(translationsPath + "inactivation")}`,
                  }
                : null,
              params.row.situation.code === "3" &&
              params.row.reactivationDate == null
                ? {
                    action: (params) => {
                      onReactivationSalesContractPayer(params.row.id);
                    },
                    icon: <PlayCircleFilledIcon fontSize="small" />,
                    text: `${t(translationsPath + "reactivation")}`,
                  }
                : null,
              params.row.suspensionDate === null &&
              params.row.situation.code === "1"
                ? {
                    action: (params) => {
                      onSuspensionSalesContractPayer(params.row.id);
                    },
                    icon: <PauseCircleFilledIcon fontSize="small" />,
                    text: `${t(translationsPath + "suspension")}`,
                  }
                : null,
            ]}
            confirmOptions={{
              title: translate("confirm.title"),
              description: translate("confirm.description"),
              confirmationButtonProps: { variant: "contained" },
            }}
          />
        </Box>
      </Box>
    </>
  );
});
