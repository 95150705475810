import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MainApi from "../../../../../../services/mainApi.service";
import { Grid } from "@mui/material";

const SALES_PRODUCT_PRICE_LIST_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesProductCtrPriceList/";
const salesProductPriceListService = new MainApi<ISelectType>(
  SALES_PRODUCT_PRICE_LIST_ROUTE
);

const translationPath = "page.4uSalesContract.registers.salesContractProducts.";

export const SelectSalesProductCtrPriceList = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(salesProductPriceListService.list);

  const { control } = useFormContext();

  const salesProductCtr = useWatch({
    name: "salesProductCtr",
    control,
  });

  const fetchSalesContractPriceList = useCallback(async () => {
    const { data, success } = await sendRequest({
      SalesProductCtrId: salesProductCtr ? salesProductCtr.id : undefined,
    });
    if (data && success) {
      setOptions(data);
    }
  }, [sendRequest, salesProductCtr]);

  useEffect(() => {
    fetchSalesContractPriceList();
  }, [fetchSalesContractPriceList]);

  return (
    <Grid item xs={12} sm={12}>
      <AutoComplete
        getOptionLabel={({ name }) => name}
        size="small"
        label={t(translationPath + "price_list")}
        name="salesProductCtrPriceList"
        fullWidth
        options={options}
      />
    </Grid>
  );
});
