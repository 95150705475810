import { Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FormInput, QPFQuickRegisterForm, SwitchInput } from "@4uhub/lib4uhub";

import { TFormType } from "../../../../models/form";
import { IUserGet } from "../../../../models/users";

import { CPFMask } from "../../../../utils/maks";

import { IEstablishment } from "@4uhub/lib4uhub";
import MainUserEstablishment from "./MainUserEstablishment";
import { useWatch } from "react-hook-form";
import { useFormContext } from "@4uhub/lib4uhub";
import { useEffect } from "react";

const translationPath = "page.register.users.";

const UserBasicData = ({
  user,
  type,
  establishmentsList,
}: {
  user?: IUserGet;
  type: TFormType;
  establishmentsList: IEstablishment[];
}) => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();

  const individual = useWatch({
    name: "individual",
    control,
  });

  useEffect(() => {
    if ((!getValues("userName") || type === "create") && individual?.document) {
      setValue("userName", individual.document.replace(/[^\w\s]/gi, ""));
    }
  }, [individual, setValue, getValues, type]);

  return (
    <>
      <Grid item xs={12} sm={12} textAlign={"end"}>
        <SwitchInput
          name="enable"
          label={t("page.register.users.enable") || "Enable"}
        />
      </Grid>

      {type === "create" && (
        <Grid item xs={12} sm={6}>
          <QPFQuickRegisterForm
            label={t("page.register.users.individual") || "Individual"}
            name="individual"
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <FormInput
          size="small"
          autoFocus={true}
          name="userName"
          label={t("page.register.users.username") || "Username"}
          fullWidth
        />
      </Grid>

      {user && (
        <Grid
          item
          xs={12}
          sm={6}
          alignItems={"center"}
          display={"flex"}
          gap={2}
        >
          <div>
            <Typography color={(theme) => theme.palette.text.secondary}>
              {t(translationPath + "name")}:{" "}
            </Typography>
            <Typography>{user.individual?.name}</Typography>
          </div>
          <div>
            <Typography color={(theme) => theme.palette.text.secondary}>
              {t(translationPath + "document")}:{" "}
            </Typography>
            <Typography>
              {user.individual?.code && CPFMask.resolve(user.individual.code)}
            </Typography>
          </div>
        </Grid>
      )}

      {type === "create" && (
        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            name="password"
            type="password"
            fullWidth
            label={t("page.register.users.password") || "Password"}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={user ? 12 : 6}>
        <FormInput
          size="small"
          name="email"
          label={t("page.register.users.email") || "Email"}
          fullWidth
        />
        {user && !user.emailConfirmed && (
          <Stack marginLeft={3}>
            <Typography color={"error"} variant="body2">
              {t("page.register.users.email_not_confirmed") ||
                "Email not confirmed by user"}
            </Typography>
          </Stack>
        )}
      </Grid>

      <MainUserEstablishment
        user={user}
        type={type}
        establishmentsList={establishmentsList}
      />
    </>
  );
};

export default UserBasicData;
