import { useTranslation } from "react-i18next";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { Grid } from "@mui/material";
import { memo } from "react";
import { ISalesContractAttachment } from "../../../../../models/sales-contract-attachments";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { InputFileForm } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../../../../../axios/axios-config";

const translationPath =
  "page.4uSalesContract.registers.salesContractAttachments.";
const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";

export const SalesContractAttachmentInformation = memo(
  ({ data }: { data: ISalesContractAttachment }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "title_form")}
            data={data.title ? data.title : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "description")}
            data={data.description ? data.description : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "attachment_date")}
            data={
              data?.attachmentDate
                ? (() => {
                    const date = new Date(data.attachmentDate);
                    date.setHours(
                      date.getHours() + date.getTimezoneOffset() / 60
                    );
                    return format(date, "P", {
                      locale:
                        language === "pt-BR"
                          ? ptBR
                          : language === "es-ES"
                          ? es
                          : enUS,
                    });
                  })()
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "attachment_type")}
            data={data.salesContractAttachmentType?.name || ""}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputFileForm
            name={"mediaFile"}
            axios={GlobalAxios}
            disable={true}
            fileRoute={FILE_ROUTE}
            fileInputProps={{
              accept: ".jpg, ,jpeg, .png, .webp",
              label: `${t("page.register.extensions.file")}`,
              maxFileSize: 2,
              mediaTypeCode: "1",
              mediaTypeIdentifier: "SalesContractAttachmentMediaType",
              parentName: "SalesContractAttachment",
              type: "Public",
              inputProps: {
                size: "small",
              },
            }}
          />
        </Grid>
      </>
    );
  }
);
