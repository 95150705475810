import { memo } from "react";

import { IBannerMobileFileProps } from "./models";
import BannerMobileFileValue from "../BannerMobileFileValue/BannerMobileFileValue";

const BannerMobileFile: React.FC<IBannerMobileFileProps> = ({
  onFileSelected,
}) => {
  return <BannerMobileFileValue onFileSelected={onFileSelected} />;
};

export default memo(BannerMobileFile);
