import { FormContainer, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createSalesContractProduct } from "../../../../../services/salesContractProduct.service";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import {
  salesContractProductSchema,
  TSalesContractProductForm,
} from "./salesContractProductSchema";
import { SelectSalesProduct } from "./SelectSalesProduct/SelectSalesProduct";
import { SelectSalesProductCtrPriceList } from "./SelectSalesProductCtrPriceList/SelectSalesProductCtrPriceList";

const translationPath = "page.4uSalesContract.registers.salesContractProducts.";

export const SalesContractProduct = memo(() => {
  const { id: salesContractId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createSalesContractProduct
  );

  const saveHandler = (v: TSalesContractProductForm) =>
    save({
      salesContractId: salesContractId ?? "",
      salesProductCtrId: v.salesProductCtr.id,
      salesProductCtrPriceListId: v.salesProductCtrPriceList
        ? v.salesProductCtrPriceList.id
        : undefined,
    });

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading;

  return (
    <FormContainer<TSalesContractProductForm>
      saveFunction={saveHandler}
      loading={loading}
      title={t(translationPath + "title")}
      schema={salesContractProductSchema}
      subtitle={t(translationPath + "new")}
      showBackButton={false}
      onSuccess={successHandle}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={(type) => (
        <Grid container spacing={2}>
          <SelectSalesProduct />
          <SelectSalesProductCtrPriceList />
        </Grid>
      )}
    />
  );
});
