import { Modal } from "@4uhub/lib4uhub";
import { memo } from "react";
import { useSalesContract } from "../../store/contexts/SalesContractContext";
import { IModalFormProps } from "./models";

export const ModalForm = memo(({ children }: IModalFormProps) => {
  const { open, onClose } = useSalesContract();
  return (
    <Modal open={open} onClose={onClose} maxDialogWidth="lg">
      {children}
    </Modal>
  );
});
