import { z } from "zod";
const translationPath =
  "chatBot.page.automations.automationMessageTicketDefaulDoc.errors.";

const sendWhenSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "send_when" }
);

const ticketDefaultDocSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "ticket_default_doc" }
);

export const automationMessageTicketDefaulDocSchema = z.object({
  id: z.string().optional(),
  ticketDefaultDoc: ticketDefaultDocSchema,
  schedules: z.string().nullish(),
  sendWhen: sendWhenSchema,
});

export type TAutomationMessageTicketDefaulDocForm = z.infer<
  typeof automationMessageTicketDefaulDocSchema
>;
