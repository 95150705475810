import { useTranslation } from "react-i18next";
import { Table, useFetch } from "@4uhub/lib4uhub";
import { Box, Typography } from "@mui/material";
import {
  GridCheckCircleIcon,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";

import { memo, useCallback, useMemo, useState } from "react";
import MainApi from "../../../../services/mainApi.service";
import { ISalesContractPriceList } from "../../../../models/sales-contract-price-list";
import { approveSalesContractPriceList } from "../../../../services/salesContractPriceList.service";
import { SalesContractPriceList } from "./SalesContractPriceListForm/SalesContractPriceList";
import { useSalesContract } from "../../../../store/contexts/SalesContractContext";
const translationsPath =
  "page.4uSalesContract.registers.salesContractPriceList.";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractPriceList/";
const service = new MainApi<ISalesContractPriceList>(serviceURL);

export type TRowClick = GridRowParams<ISalesContractPriceList>;

export const SalesContractPriceListPage = memo(
  ({ idProduct }: { idProduct: string | null }) => {
    const [salesContractPriceListForm, setSalesContractPriceListForm] =
      useState(false);
    const { onRefresh, refresh } = useSalesContract();

    const [selectedId, setSelectedId] = useState<string>();

    const { sendRequest: approve } = useFetch(approveSalesContractPriceList);

    const {
      t,
      i18n: { language },
    } = useTranslation();
    const translate = (field: string) => t(translationsPath + field);

    const columns: GridColDef<ISalesContractPriceList>[] = [
      {
        field: "name",
        headerName: translate("name"),
        renderCell: ({ value }) => (
          <Typography variant="body2">{value}</Typography>
        ),
      },
      {
        field: "code",
        maxWidth: 150,
        headerName: translate("code"),
        renderCell: ({ value }) => (
          <Typography variant="body2">{value}</Typography>
        ),
      },
      {
        field: "initialDate",
        maxWidth: 150,
        headerName: translate("initial_date"),
        renderCell: (params) => {
          if (!params.value) return "-";
          const date = new Date(params.value);
          date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
          return format(date, "P", {
            locale: language === "pt-BR" ? ptBR : enUS,
          });
        },
      },
      {
        field: "endDate",
        maxWidth: 150,
        headerName: translate("end_date"),
        renderCell: (params) => {
          if (!params.value) return "-";
          const date = new Date(params.value);
          date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
          return format(date, "P", {
            locale: language === "pt-BR" ? ptBR : enUS,
          });
        },
      },
      {
        field: "approvalDate",
        maxWidth: 150,
        headerName: translate("approval_date"),
        renderCell: (params) => {
          if (!params.value) return "-";
          const date = new Date(params.value);
          date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
          return format(date, "P", {
            locale: language === "pt-BR" ? ptBR : enUS,
          });
        },
      },
    ];

    const onApproveProductPriceList = useCallback(
      async (id: string) => {
        const { success } = await approve(id);
        if (success) {
          onRefresh();
        }
      },
      [approve, onRefresh]
    );

    const handleAdd = () => {
      setSalesContractPriceListForm(true);
    };

    const handleRowClick = (params: GridRowParams<ISalesContractPriceList>) => {
      if (!params.row) return;
      setSalesContractPriceListForm(true);
      setSelectedId(params.row.id);
    };

    const handleBack = useCallback(() => {
      setSelectedId(undefined);
      setSalesContractPriceListForm(false);
    }, []);
    const props = useMemo(() => {
      return {
        salesContractProductId: idProduct,
        refresh,
      };
    }, [idProduct, refresh]);

    return (
      <Box sx={{ height: 600, width: "100%" }}>
        {salesContractPriceListForm ? (
          <Box
            sx={(t) => ({
              p: 1,
              px: 10,
              width: "100%",
              height: "100%",
              overflow: "auto",
              backgroundColor: t.palette.background.default,
              borderRadius: 1,
            })}
          >
            <SalesContractPriceList
              id={selectedId}
              idProduct={idProduct || ""}
              onBackButton={handleBack}
            />
          </Box>
        ) : (
          <Box height={600}>
            <Table<ISalesContractPriceList>
              searchInput
              showDefaultMacroFilters={false}
              columns={columns}
              service={service}
              onAdd={handleAdd}
              onRowClick={handleRowClick}
              serviceProps={props}
              title={translate("title")}
              defaultPageSize={20}
              pageSizeOptions={[5, 10, 20]}
              addButtonLabel={translate("add")}
              searchInputPlaceHolder={translate("search")}
              rowActions={(params) => [
                params.row.approvalDate === null
                  ? {
                      action: (params) => {
                        onApproveProductPriceList(params.row.id);
                      },
                      icon: <GridCheckCircleIcon fontSize="small" />,
                      text: `${t(translationsPath + "approve")}`,
                    }
                  : null,
              ]}
              confirmOptions={{
                title: translate("confirm.title"),
                description: translate("confirm.description"),
                confirmationButtonProps: { variant: "contained" },
              }}
            />
          </Box>
        )}
      </Box>
    );
  }
);
