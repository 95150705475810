import {
  AutoComplete,
  DateInput,
  FormInput,
  QPFQuickRegisterForm,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { ISalesContractBeneficiary } from "../../../../../models/sales-contract-beneficiary";
import { listSalesContractSubStipulants } from "../../../../../services/salesContractSubStipulant.service";
import { listBeneficiaryClassifications } from "../../../../../services/salesContractBeneficiaryClassification.service";
import { SelectSalesSellers } from "./SelectSalesSellers/SelectSalesSellers";
import { SelectSalesChannel } from "./SelectSalesChannel/SelectSalesChannel";
import { SelectSalesContractPayer } from "./SelectSalesContractPayer/SelectSalesContractPayer";
import { SalesBeneficiaryHolderTypeCheckBoxes } from "./SalesBeneficiaryHolderTypeCheckBoxes/SalesBeneficiaryHolderTypeCheckBoxes";
import { SelectSalesContractPriceList } from "./SelectSalesContractPriceList/SelectSalesContractPriceList";
import { SelectSalesBeneficiaryHolder } from "./SelectSalesBeneficiaryHolder/SelectSalesBeneficiaryHolder";
import { SelectKinshipDegree } from "./SelectKinshipDegree/SelectKinshipDegree";
import { SelectSalesContractProduct } from "./SelectSalesContractProduct/SelectSalesContractProduct";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

export const SalesContractBeneficiaryForm = memo(
  ({
    type,
    dataSalesContractBeneficiary,
  }: {
    type: string;
    dataSalesContractBeneficiary: ISalesContractBeneficiary | undefined;
  }) => {
    const { t } = useTranslation();
    return (
      <>
        {type !== "create" && dataSalesContractBeneficiary?.situation && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={t(translationPath + "situation")}
              data={
                dataSalesContractBeneficiary.situation.name?.toString() ?? ""
              }
            />
          </Grid>
        )}
        {type !== "create" && dataSalesContractBeneficiary?.code && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={t(translationPath + "code")}
              data={dataSalesContractBeneficiary.code.toString()}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <DateInput
            name={"admissionDate"}
            label={t(translationPath + "admission_date") || "Admission Date"}
            initialDate={new Date()}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateInput
            name={"originalInclusionDate"}
            label={
              t(translationPath + "original_inclusion_date") ||
              "Original Inclusion Date"
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <QPFQuickRegisterForm
            label={t(translationPath + "individual_person")}
            name="individual"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            name="integrationId"
            fullWidth
            label={t(translationPath + "integration_code") || "Integration"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AutoComplete
            getOptionLabel={({ name }) => name}
            size="small"
            label={t(translationPath + "beneficiary_classification")}
            name="salesContractBeneficiaryClassification"
            request={listBeneficiaryClassifications}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AutoComplete
            getOptionLabel={({ name }) => name}
            size="small"
            label={t(translationPath + "substipulant")}
            name="salesContractSubStipulant"
            request={listSalesContractSubStipulants}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <SalesBeneficiaryHolderTypeCheckBoxes />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectSalesBeneficiaryHolder />
        </Grid>

        <SelectKinshipDegree />

        <Grid item xs={12} sm={6}>
          <SelectSalesContractProduct />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectSalesContractPriceList />
        </Grid>

        <Grid item xs={12} sm={4}>
          <SelectSalesContractPayer />
        </Grid>

        <Grid item xs={12} sm={4}>
          <SelectSalesChannel />
        </Grid>

        <Grid item xs={12} sm={4}>
          <SelectSalesSellers />
        </Grid>
      </>
    );
  }
);
