import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

import { IServiceUpdateParam } from "../utils/interfaces";
import {
  ISalesContractBeneficiary,
  ISalesContractBeneficiaryPayload,
} from "../models/sales-contract-beneficiary";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractBeneficiary";

export const createSalesContractBeneficiary = (
  payload: ISalesContractBeneficiaryPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateSalesContractBeneficiary = ({
  id,
  item,
}: IServiceUpdateParam<ISalesContractBeneficiaryPayload>) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findSalesContractBeneficiaryById = (id: string) => {
  return GlobalAxios.get<ISalesContractBeneficiary>(`${serviceURL}/${id}`);
};

export const listSalesContractBeneficiarys = () => {
  return GlobalAxios.get<ISelectType[]>(`${serviceURL}/list`);
};
export const approveSalesContractBeneficiary = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Approve/${id}`);
};
export const cancelSalesContractBeneficiary = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Cancelation/${id}`);
};
export const reestablishedSalesContractBeneficiary = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Reestablished/${id}`);
};
export const rescendSalesContractBeneficiary = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Rescend/${id}`);
};
export const suspendSalesContractBeneficiary = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Suspend/${id}`);
};
