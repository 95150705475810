import { z } from "zod";
import { buildTPath } from "./advantages-and-benefits-club.utils";

const getTranslation = (field: string) => buildTPath(`errors.${field}`);

const salesContractTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string(),
    enable: z.boolean(),
  },
  { invalid_type_error: getTranslation("contract_type") }
);

const salesContractTerminationReasonSchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  code: z.number().optional().nullable(),
});

const situationSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: getTranslation("situation") }
);

const salesContractClassificationSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.any().optional().nullable(),
  },
  { invalid_type_error: getTranslation("contract_classification") }
);

const individualStipulantSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
    establishmentId: z.string().optional().nullable(),
  },
  { invalid_type_error: getTranslation("individual_person") }
);

const legalStipulantSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: getTranslation("legal_entity") }
);

const legalEntityBenefitsAdministratorSchema = z.object({
  id: z.string(),
  identifier: z.string().optional().nullable(),
  corporateName: z.string().optional().nullable(),
  tradingName: z.string().optional().nullable(),
  document: z.string().optional().nullable(),
});

const mainContractSchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  contractTitle: z.string().optional().nullable(),
});

const salesGrantorSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
  },
  { invalid_type_error: getTranslation("grantor") }
);

const toDateOnly = (date: Date | string) => {
  let value = date instanceof Date ? date : new Date(date);

  const year = value.getFullYear();
  const month = String(value.getMonth() + 1).padStart(2, "0");
  const day = String(value.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const advantagesAndBenefitsClubSchema = z
  .object({
    id: z.string().optional().nullable(),
    entity: z.enum(["QPF", "QPJ"]).optional(),
    contractTitle: z.string().min(1, getTranslation("contract_title")),
    renewalPeriodMonths: z.string().min(1, getTranslation("renew_months")),
    code: z.string().optional().nullable(),
    contractDate: z.date({
      invalid_type_error: getTranslation("contract_date"),
    }),
    mainContract: mainContractSchema.optional().nullable(),
    situation: situationSchema.nullish(),
    salesGrantor: salesGrantorSchema,
    legalEntityBenefitsAdministrator: legalEntityBenefitsAdministratorSchema
      .optional()
      .nullable(),
    salesContractType: salesContractTypeSchema,
    salesContractClassification: salesContractClassificationSchema,
    legalEntityStipulant: legalStipulantSchema.optional().nullable(),
    individualStipulant: individualStipulantSchema.optional().nullable(),
    salesContractTerminationReason: salesContractTerminationReasonSchema
      .optional()
      .nullable(),
    integrationId: z.string().optional().nullable(),
  })
  .superRefine((value, context) => {
    const {
      renewalPeriodMonths,
      entity,
      individualStipulant,
      legalEntityStipulant,
    } = value;

    if (renewalPeriodMonths?.trim() !== "") {
      value.renewalPeriodMonths = parseInt(value.renewalPeriodMonths) as any;
    }

    if (entity === "QPF" && !individualStipulant) {
      context.addIssue({
        code: "custom",
        path: ["individualStipulant"],
        message: getTranslation("individual_person"),
      });
    } else if (entity === "QPJ" && !legalEntityStipulant) {
      context.addIssue({
        code: "custom",
        path: ["legalEntityStipulant"],
        message: getTranslation("legal_entity"),
      });
    }

    // Percorrendo as propriedades de 'value' para transformar datas para dateOnly
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        const field = value[key as keyof typeof value];

        if (!field) {
          (value as any)[key] = undefined;
        } else if (field instanceof Date) {
          (value as any)[key] = toDateOnly(field);
        }
      }
    }

    value.entity = undefined as any;
  });

export type TAdvantagesAndBenefitsClubForm = z.infer<
  typeof advantagesAndBenefitsClubSchema
>;
