import {
  AutoComplete,
  FormContainer,
  FormInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  createAutomationMessageTicketDefaulDoc,
  getAutomationMessageTicketDefaulDocById,
  updateAutomationMessageTicketDefaulDocById,
} from "../../../../../../../services/automationMessageTicketDefaulDoc.service";
import { listMainGenerics } from "../../../../../../../services/generic.service";
import { listProtocolDefault } from "../../../../../../../services/protocolDefault.service";
import {
  automationMessageTicketDefaulDocSchema,
  TAutomationMessageTicketDefaulDocForm,
} from "./automationMessageTicketDefaulDocSchema";
import { IAutomationMessageTicketDefaulDocProps } from "./models";

const translationPath =
  "chatBot.page.automations.automationMessageTicketDefaulDoc.";

export const AutomationMessageTicketDefaulDoc = memo(
  ({
    id,
    ticketAutomationRuleId,
    onBackButton,
  }: IAutomationMessageTicketDefaulDocProps) => {
    const { t } = useTranslation();

    const { sendRequest: save, loading: sLoading } = useFetch(
      createAutomationMessageTicketDefaulDoc
    );

    const { sendRequest: update, loading: uLoading } = useFetch(
      updateAutomationMessageTicketDefaulDocById
    );

    const { sendRequest: get, loading: gLoading } = useFetch(
      getAutomationMessageTicketDefaulDocById
    );

    const updateHandler = (
      v: TAutomationMessageTicketDefaulDocForm,
      id: string
    ) =>
      update({
        item: {
          id: id,
          ticketAutomationRuleId: ticketAutomationRuleId ?? "",
          schedules: v.schedules ? v.schedules : undefined,
          sendWhenId: v.sendWhen.id,
          ticketDefaultDocId: v.ticketDefaultDoc.id,
        },
        id: id,
      });

    const saveHandler = (v: TAutomationMessageTicketDefaulDocForm) =>
      save({
        ticketAutomationRuleId: ticketAutomationRuleId ?? "",
        schedules: v.schedules ? v.schedules : undefined,
        sendWhenId: v.sendWhen.id,
        ticketDefaultDocId: v.ticketDefaultDoc.id,
      });

    const getHandler = useCallback(
      async (
        id: string
      ): Promise<Treturn<TAutomationMessageTicketDefaulDocForm>> => {
        const { data, success } = await get(id);

        if (data) {
          const newData: TAutomationMessageTicketDefaulDocForm = {
            ...data,
            ticketDefaultDoc: data.ticketDefaultDoc,
            schedules: data.schedules,
            sendWhen: data.sendWhen,
          };
          return { data: newData, success: true };
        }
        return { data, success };
      },
      [get]
    );
    const successHandle = () => {
      onBackButton();
    };
    const loading = sLoading || uLoading || gLoading;
    return (
      <FormContainer<TAutomationMessageTicketDefaulDocForm>
        saveFunction={saveHandler}
        updateFunction={updateHandler}
        getFunction={getHandler}
        loading={loading}
        title={t(translationPath + "title")}
        schema={automationMessageTicketDefaulDocSchema}
        subtitle={t(translationPath + "new")}
        backButtonOnClick={onBackButton}
        redirectOnSave={false}
        onSuccess={successHandle}
        isModalForm={true}
        propId={id}
        formActionsOnBottom={false}
        fields={() => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <AutoComplete
                getOptionLabel={({ name }) => name}
                size="small"
                label={t(translationPath + "send_when")}
                name="sendWhen"
                request={listMainGenerics}
                params={{
                  Identifier: "TicketAutomationMessageProtocolSendWhen",
                }}
                clearIcon={false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutoComplete
                getOptionLabel={({ name }) => name}
                size="small"
                label={t(translationPath + "ticket_default_doc")}
                name="ticketDefaultDoc"
                request={listProtocolDefault}
                clearIcon={false}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormInput
                size="small"
                name="schedules"
                fullWidth
                label={t(translationPath + "schedules") || "schedules"}
              />
            </Grid>
          </Grid>
        )}
      />
    );
  }
);
