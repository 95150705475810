import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Chip, ChipOwnProps } from "@mui/material";

type TCode = "1" | "2" | "3" | "4";

export const translationPath = "page.4uSalesContract.registers.baseContracts.";

const SituationChip = ({ code }: { code: TCode }): ReactElement => {
  const { t } = useTranslation();

  const color = useMemo((): ChipOwnProps["color"] => {
    switch (code) {
      case "2":
        return "success";
      case "3":
        return "error";
      case "4":
        return "warning";
      default:
        return;
    }
  }, [code]);

  const label = () => {
    switch (code) {
      case "1":
        return t(translationPath + "pending");
      case "2":
        return t(translationPath + "approved");
      case "3":
        return t(translationPath + "canceled");
      case "4":
        return t(translationPath + "terminated");
    }
  };

  return <Chip size="small" color={color} label={label()} />;
};

export default SituationChip;
