import { z } from "zod";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiaryCard.errors.";

export const salesContractBeneficiaryCardSchema = z.object({
  id: z.string().optional(),
  cardIdentifier: z.string().min(1, translationPath + "card_identifier"),
  integrationId: z.string().nullish(),
  initialDate: z.date({ invalid_type_error: translationPath + "initial_date" }),
  endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
});

export type TSalesContractBeneficiaryCardForm = z.infer<
  typeof salesContractBeneficiaryCardSchema
>;
