import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import { Grid } from "@mui/material";
import {
  QPFQuickRegisterForm,
  QPJQuickRegisterForm,
  useFormContext,
} from "@4uhub/lib4uhub";
import Checkboxes from "./Checkboxes";
import { TSalesContractPayerForm } from "../salesContractPayerSchema";

const translationPath = "page.4uSalesContract.registers.salesContractPayer.";

const EntitySelect = (): ReactElement => {
  const { t } = useTranslation();
  const { control, setValue, getValues } =
    useFormContext<TSalesContractPayerForm>();
  const entityType = useWatch({ control, name: "entity", defaultValue: "QPF" });
  const entityIndividual = useWatch({
    control,
    name: "individual",
  });
  const legalEntity = useWatch({
    control,
    name: "legalEntity",
  });

  useEffect(() => {
    if (getValues("payerTitle")) return;

    const title =
      entityIndividual?.individualName || legalEntity?.corporateName;

    if (title) setValue("payerTitle", title);
  }, [entityIndividual, legalEntity, setValue, getValues]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Checkboxes />
        </Grid>
        <Grid item xs={12} sm={12}>
          {entityType === "QPF" && (
            <QPFQuickRegisterForm
              label={t(translationPath + "individual_person")}
              name="individual"
            />
          )}

          {entityType === "QPJ" && (
            <QPJQuickRegisterForm
              label={t(translationPath + "legal_entity")}
              name="legalEntity"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EntitySelect;
