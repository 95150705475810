import {
  IRadio,
  ISelectType,
  RadioGroupInput,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../../services/mainApi.service";
import { TSalesContractBeneficiaryForm } from "../salesContractBeneficiarySchema";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";
const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";

const service = new MainApiService<ISelectType>(GENERICS_ROUTE);

export const SalesBeneficiaryHolderTypeCheckBoxes = memo(() => {
  const { t } = useTranslation();
  const [radios, setRadios] = useState<IRadio<ISelectType>[]>([]);

  const { sendRequest } = useFetch(service.list);

  const { control, getValues, setValue } =
    useFormContext<TSalesContractBeneficiaryForm>();

  const typeOfHolder = useWatch({
    name: "typeOfHolder",
    control,
  });

  const [oldTypeOfHolder, setOldTypeOfHolder] = useState<ISelectType | null>(
    typeOfHolder
      ? {
          ...typeOfHolder,
          name: String(typeOfHolder.name),
          code: String(typeOfHolder.code),
        }
      : null
  );

  const fetchOptions = useCallback(async () => {
    const { data, success } = await sendRequest({
      identifier: "SalesBeneficiaryHolderType",
    });
    if (data && success) {
      const dataSorted = data.sort((a, b) =>
        a.code! < b.code! ? -1 : a.code! > b.code! ? 1 : 0
      );
      setRadios(
        dataSorted.map((item) => ({
          label: item.name,
          value: item,
        }))
      );
      const currentType = getValues("typeOfHolder");
      if (currentType) {
        setValue("typeOfHolder", currentType);
      } else {
        setValue("typeOfHolder", dataSorted[0]);
      }
    }
  }, [sendRequest, setValue, getValues]);

  useEffect(() => {
    if (typeOfHolder && oldTypeOfHolder) {
      if (typeOfHolder.code === "1" && oldTypeOfHolder.code !== "1") {
        setValue("salesBeneficiaryHolder", null);
        setValue("kinshipDegree", null);
      }
    }
  }, [typeOfHolder, setValue, oldTypeOfHolder]);

  useEffect(() => {
    return () => {
      if (!typeOfHolder) return;
      setOldTypeOfHolder({
        ...typeOfHolder,
        name: String(typeOfHolder.name),
        code: String(typeOfHolder.code),
      });
    };
  }, [typeOfHolder]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return (
    <RadioGroupInput
      inline
      name="typeOfHolder"
      valueField="id"
      groupLabel={t(translationPath + "type_of_holder")}
      radios={radios}
    />
  );
});
