import { QPJQuickRegisterForm, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

const translationPath =
  "page.4uSalesContract.registers.baseContracts.advantagesAndBenefitsClub.";

export const SelectLegalEntityBenefitsAdministrator = memo(() => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const salesContractType = useWatch({
    name: "salesContractType",
    control,
  });
  if (!salesContractType || salesContractType.code !== "1") return null;
  return (
    <Grid item xs={12} sm={6}>
      <QPJQuickRegisterForm
        label={t(translationPath + "benefit_administrators")}
        name="legalEntityBenefitsAdministrator"
      />
    </Grid>
  );
});
