import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useMemo, useState } from "react";
import MainApi from "../../../../../../services/mainApi.service";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { IAutomationMessageTicketDefaulDoc } from "../../../../../../models/automation-message-ticket-defaul-doc";
import { AutomationMessageTicketDefaulDoc } from "./AutomationMessageTicketDefaulDocForm/AutomationMessageTicketDefaulDoc";
import { Table } from "@4uhub/lib4uhub";

const TICKET_AUTOMATION_MESSAGES_TICKET_DEFAUL_DOC_ROUTE =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationMessageTicketDefaulDoc/";
const ticketAutomationService = new MainApi<IAutomationMessageTicketDefaulDoc>(
  TICKET_AUTOMATION_MESSAGES_TICKET_DEFAUL_DOC_ROUTE
);

const translationPath =
  "chatBot.page.automations.automationMessageTicketDefaulDoc.";

export const AutomationMessageTicketDefaulDocPage = memo(() => {
  const [timeRuleForm, setTimeRuleForm] = useState(false);

  const [selectedId, setSelectedId] = useState<string>();

  const { t } = useTranslation();

  const { id } = useParams();

  const columns: GridColDef<IAutomationMessageTicketDefaulDoc>[] = [
    {
      field: "sendWhen",
      headerName: t(translationPath + "send_when") || "Automation Message Type",
      renderCell: (params) => params.row.sendWhen.name,
    },
    {
      field: "ticketDefaultDoc",
      headerName:
        t(translationPath + "ticket_default_doc") || "TicketDefaultDoc",
      renderCell: (params) => params.row.ticketDefaultDoc.name,
    },
    {
      field: "schedules",
      headerName: t(translationPath + "schedules") || "schedules",
    },
  ];

  const handleAdd = () => {
    setTimeRuleForm(true);
  };

  const handleRowClick = (
    params: GridRowParams<IAutomationMessageTicketDefaulDoc>
  ) => {
    if (!params.row) return;
    setTimeRuleForm(true);
    setSelectedId(params.row.id);
  };

  const handleBack = useCallback(() => {
    setSelectedId(undefined);
    setTimeRuleForm(false);
  }, []);

  const props = useMemo(() => {
    return {
      TicketAutomationRuleId: id,
    };
  }, [id]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      {timeRuleForm && (
        <Box
          sx={(t) => ({
            p: 1,
            px: 10,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: t.palette.background.default,
            borderRadius: 1,
          })}
        >
          <AutomationMessageTicketDefaulDoc
            id={selectedId}
            ticketAutomationRuleId={id}
            onBackButton={handleBack}
          />
        </Box>
      )}
      {!timeRuleForm && (
        <Box height={600}>
          <Table<IAutomationMessageTicketDefaulDoc>
            showDefaultMacroFilters={false}
            service={ticketAutomationService}
            columns={columns}
            title={""}
            searchInput
            onAdd={handleAdd}
            onRowClick={handleRowClick}
            searchInputAutoFocus={false}
            defaultPageSize={20}
            serviceProps={props}
            pageSizeOptions={[5, 10, 20]}
            searchInputPlaceHolder={""}
            addButtonLabel={
              t(translationPath + "add") || "Add Automation Message"
            }
            confirmOptions={{
              title: t(translationPath + "confirm.title"),
              description: t(translationPath + "confirm.description"),
              confirmationButtonProps: {
                variant: "contained",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
});
