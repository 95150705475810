import { z } from "zod";
import { EBannerLocale } from "./BannersLocals/RecommendedSize";

const translationPath = "page.register.banners.errors.";

const genericSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
});

const mediaFileSchema = z.object(
  {
    mediaType: genericSchema.optional(),
    id: z.string().min(1, translationPath + "file"),
    mediaTypeId: z.string().optional(),
    isPublic: z.boolean().optional(),
    fileName: z.string().optional(),
    cdnDomain: z.string().optional(),
    filePath: z.string().optional(),
    mimeType: z.string().optional(),
    fileSize: z.number().optional(),
    file: z.instanceof(File).optional(),
  },
  { required_error: translationPath + "file" }
);

const siteAppMediaFilesMobileSchema = z.object({
  mediaType: genericSchema.optional(),
  id: z.string().min(1, translationPath + "file"),
  mediaTypeId: z.string().optional(),
  isPublic: z.boolean().optional(),
  fileName: z.string().optional(),
  cdnDomain: z.string().optional(),
  filePath: z.string().optional(),
  mimeType: z.string().optional(),
  fileSize: z.number().optional(),
  file: z.instanceof(File).optional(),
});

const languageSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "language" }
);

const siteAppType = z.object({ code: z.string() });

const siteAppSchema = z.object({
  id: z.string(),
  name: z.string(),
  siteAppType: siteAppType,
});

const bannerLocalSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.nativeEnum(EBannerLocale),
});

export const bannerSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1, translationPath + "name"),
    title: z.string().min(1, translationPath + "title"),
    alternativeText: z.string().min(1, translationPath + "alternative_text"),
    displayOrder: z.string().min(1, translationPath + "display_order"),
    enable: z.boolean(),
    language: languageSchema,
    siteApps: z.array(siteAppSchema).min(1, translationPath + "site_apps"),
    bannerMobileMediaFile: siteAppMediaFilesMobileSchema.nullish(),
    bannerMediaFile: mediaFileSchema.nullish(),
    targetingLink: z
      .string()
      .url(translationPath + "targetingLink")
      .optional()
      .or(z.literal(""))
      .nullable(),
    bannerLocals: z
      .array(bannerLocalSchema)
      .min(1, translationPath + "banner_locals"),
  })
  .superRefine((val, ctx) => {
    const displayOrder = Number(val.displayOrder);
    if (displayOrder < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["displayOrder"],
        message: translationPath + "display_order_negative", //criar erro
      });
    }
    if (!val.bannerMediaFile || !val.bannerMediaFile.id) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["bannerMediaFile"],
        message: translationPath + "file",
      });
    }
  });

export type TBannerForm = z.infer<typeof bannerSchema>;
