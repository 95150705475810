import { AutoComplete, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listSalesContractPayerBySalesContract } from "../../../../../../services/salesContractPayer.service";
import { useParams } from "react-router-dom";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

export const SelectSalesContractPayer = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { id: salesContractId } = useParams();

  const { sendRequest } = useFetch(listSalesContractPayerBySalesContract);

  const fetchOptions = useCallback(async () => {
    const { data, success } = await sendRequest({
      SalesContractId: salesContractId,
    });
    if (data && success) {
      setOptions(
        data.map((item) => ({
          id: item.id,
          name: item.payerTitle,
        }))
      );
    }
  }, [sendRequest, salesContractId]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return (
    <AutoComplete
      getOptionLabel={({ name }) => name}
      size="small"
      label={t(translationPath + "sales_payer")}
      name="salesContractPayer"
      options={options}
    />
  );
});
