import { GlobalAxios } from "../axios/axios-config";
import {
  ISalesContract,
  ISalesContractPayload,
} from "../models/sales-contract";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesContract";

export const createSalesContract = (payload: ISalesContractPayload) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateSalesContract = ({
  id,
  item,
}: {
  id: string;
  item: ISalesContractPayload;
}) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findSalesContractById = (id: string) => {
  return GlobalAxios.get<ISalesContract>(`${serviceURL}/${id}`);
};

export const listSalesContract = () => {
  return GlobalAxios.get(`${serviceURL}/list`);
};

export const approveSalesContract = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Approve/${id}`);
};

export const cancelSalesContract = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Cancel/${id}`);
};

export const rescendSalesContract = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Rescend/${id}`);
};

export const reactivateSalesContract = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Reactivate/${id}`);
};
