import { RouteObject } from "react-router-dom";
import WelcomePage from "../pages/welcomePage";

const esign: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
];

export default esign;
