import { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useWatch } from "react-hook-form";

import Dates from "./Dates";
import { Box, Grid } from "@mui/material";
import EntitySelect from "./EntitySelect";
import GrantorSelect from "./GrantorSelect";
import { FormInput, useFormContext } from "@4uhub/lib4uhub";
import MainContractSelect from "./MainContractSelect";
import SalesContractTypeSelect from "./ContractTypeSelect";
import ContractClassificationSelect from "./ContractClassificationSelect";

import { buildTPath } from "../../advantages-and-benefits-club.utils";
import { TAdvantagesAndBenefitsClubForm } from "../../advantage-and-benefits-club.schema";
import FormInformation from "../../../../../../components/FormInformation/FormInformation";
import { ISalesContract } from "../../../../../../models/sales-contract";
import { SelectLegalEntityBenefitsAdministrator } from "./SelectLegalEntityBenefitsAdministrator/SelectLegalEntityBenefitsAdministrator";

export const Fields = memo(
  ({
    type,
    dataSalesContract,
  }: {
    type: string;
    dataSalesContract: ISalesContract | undefined;
  }) => {
    const { id } = useParams();
    const { t } = useTranslation();

    const translate = (field: string) => t(buildTPath(field));

    const { control, setValue } =
      useFormContext<TAdvantagesAndBenefitsClubForm>();
    const renewalPeriodMonths = useWatch({
      control,
      name: "renewalPeriodMonths",
    });

    useEffect(() => {
      if (id) return;

      setValue("renewalPeriodMonths", "12");
    }, [setValue, id]);

    const validateRenewalPeriodMonths = useCallback(() => {
      if (!renewalPeriodMonths) {
        setValue("renewalPeriodMonths", "12");
      }
    }, [renewalPeriodMonths, setValue]);

    return (
      <Box
        sx={(t) => ({
          p: 1,
          px: 7,
          width: "100%",
          height: "calc(100vh - 18rem)",
          overflow: "auto",
          backgroundColor: t.palette.background.default,
          borderRadius: 1,
        })}
      >
        <Grid
          container
          spacing={2}
          sx={(t) => ({
            py: 6,
          })}
        >
          {type !== "create" && dataSalesContract?.situation && (
            <Grid item xs={12} sm={6}>
              <FormInformation
                label={t(translate("situation"))}
                data={dataSalesContract.situation.name?.toString() ?? ""}
              />
            </Grid>
          )}
          {type !== "create" && dataSalesContract?.code && (
            <Grid item xs={12} sm={6}>
              <FormInformation
                label={t(translate("code"))}
                data={dataSalesContract.code.toString()}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <FormInput
              name="contractTitle"
              size="small"
              label={translate("contract_title")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              name="renewalPeriodMonths"
              size="small"
              defaultValue="12"
              fullWidth
              onBlur={validateRenewalPeriodMonths}
              label={translate("renewal_period")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              name="integrationId"
              size="small"
              label={translate("integration_code")}
              fullWidth
            />
          </Grid>

          <EntitySelect />

          <Grid item xs={12} sm={6}>
            <GrantorSelect />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MainContractSelect />
          </Grid>

          <SalesContractTypeSelect />

          <SelectLegalEntityBenefitsAdministrator />

          <Grid item xs={12} sm={6}>
            <ContractClassificationSelect />
          </Grid>

          <Dates />

          {/* <Section label={translate("additional_data")} /> */}
        </Grid>
      </Box>
    );
  }
);
