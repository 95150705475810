import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listSaleChannels } from "../../../../../../services/saleChannel.service";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

export const SelectSalesChannel = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(listSaleChannels);

  const { setValue } = useFormContext();

  const valueChangeHandler = useCallback(() => {
    setValue("salesSeller", null);
  }, [setValue]);

  const fetchOptions = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setOptions(
        data.map((item) => ({
          id: item.id,
          name: item.titleChannel,
        }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return (
    <AutoComplete
      getOptionLabel={({ name }) => name}
      size="small"
      label={t(translationPath + "sales_channel")}
      name="salesChannel"
      options={options}
      onValueChange={valueChangeHandler}
    />
  );
});
