import { z } from "zod";
const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.errors.";

const kinshipDegreeSchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
});

const individualSchema = z.object(
  {
    identifier: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "individual_person" }
);

const salesContractBeneficiaryClassificationSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "beneficiary_classification" }
);
const typeOfHolderSchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
});
const salesContractPriceListSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "sales_price_list" }
);
const salesChannelSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "sales_channel" }
);
const salesSellerSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "sales_seller" }
);
const salesContractSubStipulantSchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  code: z.number().optional().nullable(),
});
const salesBeneficiaryHolderSchema = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  code: z.number().optional().nullable(),
});
const salesContractProductSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.number().optional().nullable(),
  },
  { invalid_type_error: translationPath + "sales_product" }
);
const salesContractPayerSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "sales_payer" }
);

const situationSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "situation" }
);

export const salesContractBeneficiarySchema = z.object({
  id: z.string().optional(),
  code: z.string().nullish(),
  integrationId: z.string().nullish(),
  situation: situationSchema.nullish(),
  individual: individualSchema,
  kinshipDegree: kinshipDegreeSchema.nullish(),
  salesContractBeneficiaryClassification:
    salesContractBeneficiaryClassificationSchema,
  typeOfHolder: typeOfHolderSchema,
  salesContractPriceList: salesContractPriceListSchema,
  salesChannel: salesChannelSchema,
  salesContractSubStipulant: salesContractSubStipulantSchema.nullish(),
  salesContractProduct: salesContractProductSchema,
  salesBeneficiaryHolder: salesBeneficiaryHolderSchema.nullish(),
  salesSeller: salesSellerSchema,
  salesContractPayer: salesContractPayerSchema,
  admissionDate: z.date({
    invalid_type_error: translationPath + "admission_date",
  }),
  originalInclusionDate: z.date().nullish(),
});

export type TSalesContractBeneficiaryForm = z.infer<
  typeof salesContractBeneficiarySchema
>;
