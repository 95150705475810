import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FormContainer, IInputFile, Treturn, useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { ISalesContractAttachment } from "../../../../../models/sales-contract-attachments";
import {
  createSalesContractAttachment,
  getSalesContractAttachmentById,
  updateSalesContractAttachmentById,
} from "../../../../../services/salesContractAttachment.service";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import { ISalesContractProps } from "./models";
import {
  salesContractAttachmentSchema,
  TSalesContractAttachmentForm,
} from "./salesContractAttachment.schema";
import { SalesContractAttachmentForm } from "./SalesContractAttachmentForm";
import { SalesContractAttachmentInformation } from "./SalesContractAttachmentInformation";

const translationPath =
  "page.4uSalesContract.registers.salesContractAttachments.";

export const SalesContractAttachment = memo(({ id }: ISalesContractProps) => {
  const { id: salesContractId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();
  const [deleteFilesList, setDeleteFilesList] = useState<string[]>([]);

  const [dataSalesContractAttachment, setDataSalesContractAttachment] =
    useState<ISalesContractAttachment>();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createSalesContractAttachment
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateSalesContractAttachmentById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getSalesContractAttachmentById
  );

  const onDeleteFile = (file: IInputFile) => {
    setDeleteFilesList((prevState) => [...prevState, file.id]);
  };

  const updateHandler = (v: TSalesContractAttachmentForm, id: string) => {
    return update({
      item: {
        id: id,
        title: v.title,
        description: v.description,
        salesContractId: salesContractId ?? "",
        attachmentDate: v.attachmentDate.toISOString().substring(0, 10),
        salesContractAttachmentTypeId: v.salesContractAttachmentType
          ? v.salesContractAttachmentType.id
          : "",
        mediaFileId: v.mediaFile.id,
        deleteMediaFilesIds: deleteFilesList,
      },
      id: id,
    });
  };

  const saveHandler = (v: TSalesContractAttachmentForm) =>
    save({
      title: v.title,
      description: v.description,
      salesContractId: salesContractId ?? "",
      attachmentDate: v.attachmentDate.toISOString().substring(0, 10),
      salesContractAttachmentTypeId: v.salesContractAttachmentType
        ? v.salesContractAttachmentType.id
        : "",
      mediaFileId: v.mediaFile.id,
      deleteMediaFilesIds: deleteFilesList,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TSalesContractAttachmentForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setDataSalesContractAttachment(data);
        const newData: TSalesContractAttachmentForm = {
          ...data,
          title: data.title,
          description: data.description,
          salesContractAttachmentType: {
            ...data.salesContractAttachmentType,
            code: Number(data.salesContractAttachmentType.code),
          },
          attachmentDate: new Date(`${data.attachmentDate}T00:00:00`),
          mediaFile: {
            cdnDomain: data.cdnDomain,
            id: data.id,
            mediaTypeId: data.mediaTypeId,
            isPublic: data.isPublic,
            fileName: data.fileName,
            filePath: data.filePath,
            mimeType: data.mimeType,
            fileSize: data.fileSize,
            mediaType: data.mediaType,
          },
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TSalesContractAttachmentForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "title")}
      schema={salesContractAttachmentSchema}
      subtitle={t(translationPath + "new_attachment")}
      subtitleWatchField={"title"}
      showBackButton={false}
      onSuccess={successHandle}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      propId={id}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      actionsBar={!dataSalesContractAttachment?.approvalDate}
      fields={(type) => (
        <Grid container spacing={2}>
          {type !== "create" && dataSalesContractAttachment?.approvalDate ? (
            <SalesContractAttachmentInformation
              data={dataSalesContractAttachment}
            />
          ) : (
            <SalesContractAttachmentForm id={id} onDeleteFile={onDeleteFile} />
          )}
        </Grid>
      )}
    />
  );
});
