import { AutoComplete, useFormContext } from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { listSalesContractBeneficiarys } from "../../../../../../services/salesContractBeneficiary.service";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

export const SelectSalesBeneficiaryHolder = memo(() => {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext();

  const typeOfHolder = useWatch({
    name: "typeOfHolder",
    control,
  });

  const valueChangeHandler = useCallback(() => {
    setValue("salesBeneficiaryHolder", null);
    setValue("kinshipDegree", null);
  }, [setValue]);

  return (
    <AutoComplete
      getOptionLabel={({ name }) => name}
      size="small"
      label={t(translationPath + "beneficiary_holder")}
      name="salesBeneficiaryHolder"
      request={listSalesContractBeneficiarys}
      onValueChange={valueChangeHandler}
      disable={typeOfHolder && typeOfHolder.code === "1"}
    />
  );
});
