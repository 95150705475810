import { memo } from "react";
import { useSalesContract } from "../../../../../../store/contexts/SalesContractContext";
import { SalesContractAttachment } from "../../../SalesContractAttachments/SalesContractAttachmentForm/SalesContractAttachment";
import { SalesContractHistory } from "../../../SalesContractHistories/SalesContractHistoryForm/SalesContractHistory";
import { SalesContractSubStipulant } from "../../../SalesContractSubStipulant/SalesContractSubStipulantForm/SalesContractSubStipulant";
import { SalesContractBeneficiary } from "../../../SalesContractBeneficiary/SalesContractBeneficiaryForm/SalesContractBeneficiary";
import { SalesContractPayer } from "../../../SalesContractPayer/SalesContractPayerForm/SalesContractPayer";
import { SalesContractProduct } from "../../../SalesContractProduct/SalesContractProductForm/SalesContractProduct";
import { SalesContractPayerFinantialPage } from "../../../SalesContractPayerFinantial/SalesContractPayerFinantialPage";
import { SalesContractPriceListPage } from "../../../SalesContractPriceList/SalesContractPriceListPage";
import { SalesContractBeneficiaryCardPage } from "../../../SalesContractBeneficiaryCard/SalesContractBeneficiaryCardPage";

export const RenderForms = memo(() => {
  const { propId, tabName } = useSalesContract();
  switch (tabName) {
    case "history":
      return <SalesContractHistory id={propId} />;
    case "attachment":
      return <SalesContractAttachment id={propId} />;
    case "substipulant":
      return <SalesContractSubStipulant id={propId} />;
    case "beneficiary":
      return <SalesContractBeneficiary id={propId} />;
    case "payer":
      return <SalesContractPayer id={propId} />;
    case "product":
      return <SalesContractProduct />;
    case "payerFinantial":
      return (
        <SalesContractPayerFinantialPage idPayer={propId ? propId : null} />
      );
    case "contractPriceList":
      return <SalesContractPriceListPage idProduct={propId ? propId : null} />;
    case "salesContractBeneficiaryCard":
      return (
        <SalesContractBeneficiaryCardPage
          idBeneficiary={propId ? propId : null}
        />
      );
    default:
      return null;
  }
});
