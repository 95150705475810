import { memo } from "react";

import { IBannerFileProps } from "./models";
import BannerFileValue from "../BannerFileValue/BannerFileValue";

const BannerFile: React.FC<IBannerFileProps> = ({ onFileSelected }) => {
  return <BannerFileValue onFileSelected={onFileSelected} />;
};

export default memo(BannerFile);
