import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  DateInput,
  FormContainer,
  FormInput,
  ISelectType,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../../services/mainApi.service";
import { useCallback } from "react";
import {
  createHealthProgramContentRule,
  getHealthProgramContentRuleById,
  updateHealthProgramContentRuleById,
} from "../../../../../services/healthProgramContentRule.service";
import {
  THealthProgramContentRuleForm,
  healthProgramContentRuleSchema,
} from "./HealthProgramContentRuleSchema";
import { listMainGenerics } from "../../../../../services/generic.service";

const translationPath = "page.4uHcp.hcp.healthProgramsContentRules.";

const HEALTH_PROGRAM_TYPE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgram/";
const healthProgramTypeService = new MainApi<ISelectType>(
  HEALTH_PROGRAM_TYPE_ROUTE
);

const HealthProgramContentRule = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createHealthProgramContentRule
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateHealthProgramContentRuleById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getHealthProgramContentRuleById
  );

  const saveHandler = (v: THealthProgramContentRuleForm) =>
    save({
      ...v,
      healthProgramId: v.healthProgram.id,
      initialDate: v.initialDate.toISOString(),
      endDate: v.endDate.toISOString(),
      contentViewTypeHcpCode: v.contentViewTypeHcp.code
        ? v.contentViewTypeHcp.code
        : undefined,
    });

  const updateHandler = (v: THealthProgramContentRuleForm, id: string) =>
    update({
      item: {
        ...v,
        healthProgramId: v.healthProgram.id,
        initialDate: v.initialDate.toISOString(),
        endDate: v.endDate.toISOString(),
        contentViewTypeHcpCode: v.contentViewTypeHcp.code
          ? v.contentViewTypeHcp.code
          : undefined,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<THealthProgramContentRuleForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: THealthProgramContentRuleForm = {
          ...data,
          initialDate: new Date(data.initialDate),
          endDate: new Date(data.endDate),
          healthProgram: {
            id: data.healthProgram.id,
            name: data.healthProgram.name,
          },
          contentViewTypeHcp: {
            id: data.contentViewTypeHcp.id,
            name: data.contentViewTypeHcp.name,
            code: data.contentViewTypeHcp.code
              ? data.contentViewTypeHcp.code.toString()
              : null,
          },
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<THealthProgramContentRuleForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "health_program_content_rule")}
      schema={healthProgramContentRuleSchema}
      subtitle={t(translationPath + "new_health_program_content_rule")}
      subtitleWatchField={"name"}
      fields={(type) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              name="name"
              label={t(translationPath + "name") || "Name"}
              size="small"
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "health_program") || "Health Program"}
              name="healthProgram"
              request={healthProgramTypeService.list}
              disable={type === "update"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput
              name="initialDate"
              label={t(translationPath + "initial_date") || "Initial Date"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput
              name="endDate"
              label={t(translationPath + "end_date") || "End Date"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              getOptionLabel={({ name }) => name}
              size="small"
              label={t(translationPath + "content_view_type_hcp")}
              name="contentViewTypeHcp"
              request={listMainGenerics}
              params={{ Identifier: "ContentViewTypeHcp" }}
              clearIcon={false}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default HealthProgramContentRule;
