import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listSalesContractProductBySalesContract } from "../../../../../../services/salesContractProduct.service";
import { useParams } from "react-router-dom";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

export const SelectSalesContractProduct = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(listSalesContractProductBySalesContract);

  const { id: salesContractId } = useParams();

  const { setValue } = useFormContext();

  const valueChangeHandler = useCallback(() => {
    setValue("salesContractPriceList", null);
  }, [setValue]);

  const fetchOptions = useCallback(async () => {
    if (!salesContractId) return;
    const { data, success } = await sendRequest({
      SalesContractId: salesContractId,
    });
    if (data && success) {
      setOptions(data);
    }
  }, [sendRequest, salesContractId]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return (
    <AutoComplete
      getOptionLabel={({ name }) => name}
      size="small"
      label={t(translationPath + "sales_product")}
      name="salesContractProduct"
      options={options}
      onValueChange={valueChangeHandler}
    />
  );
});
