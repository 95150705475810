import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { ISalesContractBeneficiary } from "../../../../../models/sales-contract-beneficiary";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

export const SalesContractBeneficiaryInformation = memo(
  ({ data }: { data: ISalesContractBeneficiary }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "situation")}
            data={data.situation.name?.toString() ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "code")}
            data={data.code?.toString() ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "admission_date") || "Admission Date"}
            data={
              data?.admissionDate
                ? (() => {
                    const date = new Date(data.admissionDate);
                    date.setHours(
                      date.getHours() + date.getTimezoneOffset() / 60
                    );
                    return format(date, "P", {
                      locale:
                        language === "pt-BR"
                          ? ptBR
                          : language === "es-ES"
                          ? es
                          : enUS,
                    });
                  })()
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInformation
            label={
              t(translationPath + "original_inclusion_date") ||
              "Original Inclusion Date"
            }
            data={
              data?.originalInclusionDate
                ? (() => {
                    const date = new Date(data.originalInclusionDate);
                    date.setHours(
                      date.getHours() + date.getTimezoneOffset() / 60
                    );
                    return format(date, "P", {
                      locale:
                        language === "pt-BR"
                          ? ptBR
                          : language === "es-ES"
                          ? es
                          : enUS,
                    });
                  })()
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "individual_person")}
            data={data.individual?.individualName ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInformation
            label={t(translationPath + "integration_code")}
            data={data.integrationId ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormInformation
            label={t(translationPath + "beneficiary_classification")}
            data={data.salesContractBeneficiaryClassification?.name ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormInformation
            label={t(translationPath + "substipulant")}
            data={
              data.salesContractSubStipulant?.individual
                ? data.salesContractSubStipulant?.individual?.individualName?.toString()
                : data.salesContractSubStipulant?.legalEntity?.corporateName ??
                  ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormInformation
            label={t(translationPath + "type_of_holder")}
            data={data.typeOfHolder?.name ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormInformation
            label={t(translationPath + "beneficiary_holder")}
            data={data.salesBeneficiaryHolder?.name ?? ""}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormInformation
            label={t(translationPath + "kinship_degree")}
            data={data.kinshipDegree?.name ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInformation
            label={t(translationPath + "sales_product")}
            data={
              data.salesContractPriceList.salesContractProduct.salesProductCtr
                .name ?? ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInformation
            label={t(translationPath + "sales_price_list")}
            data={data.salesContractPriceList?.name ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInformation
            label={t(translationPath + "sales_payer")}
            data={data.salesContractPayer?.payerTitle ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInformation
            label={t(translationPath + "sales_seller")}
            data={data.salesSeller?.titleSeller ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInformation
            label={t(translationPath + "sales_channel")}
            data={data.salesChannel?.titleChannel ?? ""}
          />
        </Grid>
      </>
    );
  }
);
