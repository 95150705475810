import { memo, useCallback, useState } from "react";
import { ISalesContractProps } from "../../SalesContractHistories/SalesContractHistoryForm/models";
import { useParams } from "react-router-dom";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import { useTranslation } from "react-i18next";
import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { ISalesContractBeneficiary } from "../../../../../models/sales-contract-beneficiary";
import {
  createSalesContractBeneficiary,
  findSalesContractBeneficiaryById,
  updateSalesContractBeneficiary,
} from "../../../../../services/salesContractBeneficiary.service";
import { SalesContractBeneficiaryForm } from "./SalesContractBeneficiaryForm";
import { SalesContractBeneficiaryInformation } from "./SalesContractBeneficiaryInformation";
import {
  salesContractBeneficiarySchema,
  TSalesContractBeneficiaryForm,
} from "./salesContractBeneficiarySchema";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

export const SalesContractBeneficiary = memo(({ id }: ISalesContractProps) => {
  const { id: salesContractId } = useParams();
  const { onClose, onRefresh } = useSalesContract();
  const { t } = useTranslation();
  const [dataSalesContractBeneficiary, setDataSalesContractBeneficiary] =
    useState<ISalesContractBeneficiary>();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createSalesContractBeneficiary
  );

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateSalesContractBeneficiary
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    findSalesContractBeneficiaryById
  );

  const updateHandler = (v: TSalesContractBeneficiaryForm, id: string) => {
    return update({
      item: {
        id: id,
        admissionDate: v.admissionDate.toISOString().substring(0, 10),
        originalInclusionDate: v.originalInclusionDate
          ? v.originalInclusionDate.toISOString().substring(0, 10)
          : null,
        individualId: v.individual.identifier,
        salesChannelId: v.salesChannel.id,
        typeOfHolderId: v.typeOfHolder.id,
        salesContractPayerId: v.salesContractPayer.id,
        salesContractPriceListId: v.salesContractPriceList.id,
        salesContractSubStipulantId: v.salesContractSubStipulant
          ? v.salesContractSubStipulant.id
          : undefined,
        salesBeneficiaryHolderId: v.salesBeneficiaryHolder
          ? v.salesBeneficiaryHolder.id
          : undefined,
        salesSellerId: v.salesSeller.id,
        salesContractId: salesContractId ?? "",
        kinshipDegreeId: v.kinshipDegree ? v.kinshipDegree.id : undefined,
        salesContractBeneficiaryClassificationId:
          v.salesContractBeneficiaryClassification.id,
        integrationId: v.integrationId ? v.integrationId : undefined,
      },
      id: id,
    });
  };

  const saveHandler = (v: TSalesContractBeneficiaryForm) =>
    save({
      admissionDate: v.admissionDate.toISOString().substring(0, 10),
      originalInclusionDate: v.originalInclusionDate
        ? v.originalInclusionDate.toISOString().substring(0, 10)
        : null,
      individualId: v.individual.identifier,
      salesChannelId: v.salesChannel.id,
      typeOfHolderId: v.typeOfHolder.id,
      salesContractPayerId: v.salesContractPayer.id,
      salesContractPriceListId: v.salesContractPriceList.id,
      salesContractSubStipulantId: v.salesContractSubStipulant
        ? v.salesContractSubStipulant.id
        : undefined,
      salesBeneficiaryHolderId: v.salesBeneficiaryHolder
        ? v.salesBeneficiaryHolder.id
        : undefined,
      salesSellerId: v.salesSeller.id,
      salesContractId: salesContractId ?? "",
      kinshipDegreeId: v.kinshipDegree ? v.kinshipDegree.id : undefined,
      salesContractBeneficiaryClassificationId:
        v.salesContractBeneficiaryClassification.id,
      integrationId: v.integrationId ? v.integrationId : undefined,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TSalesContractBeneficiaryForm>> => {
      const { data, success } = await get(id);
      if (data) {
        setDataSalesContractBeneficiary(data);
        const newData: TSalesContractBeneficiaryForm = {
          ...data,
          typeOfHolder: data.typeOfHolder,
          code: data.code ? data.code.toString() : "",
          admissionDate: new Date(`${data.admissionDate}T00:00:00`),
          originalInclusionDate: data.originalInclusionDate
            ? new Date(`${data.originalInclusionDate}T00:00:00`)
            : null,
          individual: {
            ...data.individual,
            name: data.individual.individualName,
          },
          salesChannel: {
            ...data.salesChannel,
            name: data.salesChannel.titleChannel,
            code: data.salesChannel.code?.toString(),
          },
          salesContractPayer: {
            ...data.salesContractPayer,
            name: data.salesContractPayer.payerTitle,
            code: data.salesContractPayer.code?.toString(),
          },
          salesContractPriceList: data.salesContractPriceList || null,
          salesSeller: {
            ...data.salesSeller,
            name: data.salesSeller.titleSeller,
            code: data.salesSeller.code
              ? data.salesSeller.code?.toString()
              : null,
          },
          kinshipDegree: data.kinshipDegree || null,
          salesContractBeneficiaryClassification:
            data.salesContractBeneficiaryClassification || null,
          salesContractSubStipulant: data.salesContractSubStipulant
            ? {
                id: data.salesContractSubStipulant.id,
                name: data.salesContractSubStipulant.individual
                  ? data.salesContractSubStipulant.individual?.individualName
                  : data.salesContractSubStipulant.legalEntity?.corporateName,
                code: data.salesContractSubStipulant.code
                  ? data.salesContractSubStipulant.code
                  : null,
              }
            : null,
          integrationId: data.integrationId,
          salesBeneficiaryHolder: data.salesBeneficiaryHolder
            ? {
                ...data.salesBeneficiaryHolder,
                name: data.salesBeneficiaryHolder.name,
                code: data.salesBeneficiaryHolder
                  ? Number(data.salesBeneficiaryHolder.code)
                  : null,
              }
            : null,
          salesContractProduct: {
            name: data.salesContractPriceList.salesContractProduct
              .salesProductCtr.name,
            code: data.salesContractPriceList.salesContractProduct
              .salesProductCtr.code,
            id: data.salesContractPriceList.salesContractProduct.id,
          },
        };

        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const successHandle = () => {
    onClose();
    onRefresh();
  };

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TSalesContractBeneficiaryForm>
      title={t(translationPath + "title")}
      subtitle=""
      getFunction={getHandler}
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      onSuccess={successHandle}
      showBackButton={false}
      redirectOnSave={false}
      backButtonOnClick={onClose}
      isModalForm={true}
      actionsBar={dataSalesContractBeneficiary?.approvalDate ? false : true}
      propId={id}
      loading={loading}
      schema={salesContractBeneficiarySchema}
      marginBottom={0}
      customHeight={"fit-content"}
      formActionsOnBottom={false}
      fields={(type) => (
        <Grid container spacing={2}>
          {type !== "create" && dataSalesContractBeneficiary?.approvalDate ? (
            <SalesContractBeneficiaryInformation
              data={dataSalesContractBeneficiary}
            />
          ) : (
            <SalesContractBeneficiaryForm
              type={type}
              dataSalesContractBeneficiary={dataSalesContractBeneficiary}
            />
          )}
        </Grid>
      )}
    />
  );
});
