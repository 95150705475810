import { GlobalAxios } from "../axios/axios-config";
import {
  ISalesContractBeneficiaryCard,
  ISalesContractBeneficiaryCardPayload,
} from "../models/sales-contract-beneficiary-card";
import { IServiceUpdateParam } from "../utils/interfaces";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractBeneficiaryCard";

export const createSalesContractBeneficiaryCard = (
  payload: ISalesContractBeneficiaryCardPayload
) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateSalesContractBeneficiaryCard = ({
  id,
  item,
}: IServiceUpdateParam<ISalesContractBeneficiaryCardPayload>) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const getSalesContractBeneficiaryCardById = (id: string) => {
  return GlobalAxios.get<ISalesContractBeneficiaryCard>(`${serviceURL}/${id}`);
};
