import {
  AutoComplete,
  AutoCompleteAutomations,
  DateInput,
  FormInput,
  ISelectType,
  SwitchInput,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GlobalAxios } from "../../../../../../axios/axios-config";
import FormInformation from "../../../../../../components/FormInformation/FormInformation";
import MainApi from "../../../../../../services/mainApi.service";
import { useAutomationRules } from "../../../../../../store/contexts/AutomationRulesContext";
import { TAutomationRuleForm } from "../automationRuleSchema";
import { BoardList } from "./BoardList/BoardList";
import { IAutomationRuleBasicData } from "./models";
import { SelectTicketEvent } from "./SelectTicketEvent";
import { WhatsappTemplate } from "./WhatsappTemplate";

const TICKET_SERVICES_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServices/";
const ticketServicesService = new MainApi<ISelectType>(TICKET_SERVICES_ROUTE);

const translationPath = "chatBot.page.automations.automationRules.";

export const BasicData = memo(
  ({ onGetTicketAutomation }: IAutomationRuleBasicData) => {
    const { t } = useTranslation();

    const { id } = useParams();

    const { control, setValue } = useFormContext<TAutomationRuleForm>();

    const { selectAutomation } = useAutomationRules();

    const currentAutomation = useWatch({ name: "ticketAutomation", control });

    const currentAutomationMemoized = useMemo(
      () => currentAutomation,
      [currentAutomation]
    );

    useEffect(() => {
      onGetTicketAutomation(
        currentAutomationMemoized
          ? {
              ...currentAutomationMemoized,
              code: currentAutomationMemoized.code ?? "",
              automationAction: {
                ...currentAutomationMemoized.automationAction,
                code: currentAutomationMemoized.automationAction.code ?? "",
                description:
                  currentAutomationMemoized.automationAction.description ?? "",
              },
            }
          : null
      );
    }, [selectAutomation, onGetTicketAutomation, currentAutomationMemoized]);

    useEffect(() => {
      selectAutomation(
        currentAutomationMemoized
          ? {
              ...currentAutomationMemoized,
              automationAction: {
                ...currentAutomationMemoized.automationAction,
                code: currentAutomationMemoized.automationAction.code ?? "",
                description:
                  currentAutomationMemoized.automationAction.description ??
                  undefined,
              },
              code: currentAutomationMemoized.code ?? undefined,
            }
          : null
      );
    }, [currentAutomationMemoized, selectAutomation]);

    const cleanTicketEvent = useCallback(() => {
      setValue("ticketEvent", null);
    }, [setValue]);

    const selectAutomationProps = useMemo(() => {
      return {
        AllowWhatsappTemplate: true,
      };
    }, []);

    const showPendent = ["1", "2", "3", "4", "5", "6", "9", "10", "11"];

    return (
      <>
        <Grid item xs={12} sm={12} textAlign={"end"}>
          <SwitchInput name="enable" label={t(translationPath + "enable")} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInput
            name="name"
            label={t(translationPath + "name")}
            size="small"
            fullWidth
            autoFocus
          />
        </Grid>

        {id && (
          <Grid item xs={12} sm={6}>
            <FormInformation
              label={t(translationPath + "automation")}
              data={currentAutomation ? currentAutomation.name : "-"}
              minHeight={40}
            />
          </Grid>
        )}

        {!id && (
          <Grid item xs={12} sm={6}>
            <AutoCompleteAutomations
              params={selectAutomationProps}
              name="ticketAutomation"
              axios={GlobalAxios}
              label={t(translationPath + "automation")}
              getOptionLabel={(option) => option.name}
              size="small"
              onValueChange={cleanTicketEvent}
              fullWidth
            />
          </Grid>
        )}

        <SelectTicketEvent />

        <Grid item xs={12} sm={6}>
          <AutoComplete
            name="ticketService"
            label={t(translationPath + "ticket_service")}
            getOptionLabel={(option) => option.name}
            size="small"
            fullWidth
            request={ticketServicesService.list}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateInput
            name="initialDate"
            label={t(translationPath + "initial_date")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateInput name="endDate" label={t(translationPath + "end_date")} />
        </Grid>

        <WhatsappTemplate />

        {currentAutomation && currentAutomation.code !== "8" && (
          <BoardList
            label={t(translationPath + "robot") ?? undefined}
            boardFieldName="ticketChannelRobot"
            listFieldName="ticketChannelStepRobot"
          />
        )}

        {currentAutomation &&
          currentAutomation.allowTransfer &&
          currentAutomation.code !== "8" &&
          currentAutomation.code !== "1" &&
          currentAutomation.code !== "5" &&
          currentAutomation.code !== "11" && (
            <BoardList
              label={t(translationPath + "attendant") ?? undefined}
              boardFieldName="ticketChannelAttendant"
              listFieldName="ticketChannelStepAttendant"
            />
          )}

        {currentAutomation && showPendent.includes(currentAutomation.code!) && (
          <BoardList
            label={t(translationPath + "pendent") ?? undefined}
            boardFieldName="ticketChannelPendent"
            listFieldName="ticketChannelStepPendent"
          />
        )}

        {currentAutomation && (
          <BoardList
            label={t(translationPath + "when_error") ?? undefined}
            boardFieldName="ticketChannelWhenError"
            listFieldName="ticketChannelStepWhenError"
          />
        )}
      </>
    );
  }
);
