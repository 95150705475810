import { GlobalAxios } from "../axios/axios-config";
import {
  IAutomationMessageTicketDefaulDoc,
  INewAutomationMessageTicketDefaulDoc,
} from "../models/automation-message-ticket-defaul-doc";

const AUTOMATION_RULE_ROUTE =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationMessageTicketDefaulDoc/";

export const createAutomationMessageTicketDefaulDoc = (
  item: INewAutomationMessageTicketDefaulDoc
) => {
  return GlobalAxios.post(AUTOMATION_RULE_ROUTE, item);
};

export const getAutomationMessageTicketDefaulDocById = (id: string) => {
  return GlobalAxios.get<IAutomationMessageTicketDefaulDoc>(
    AUTOMATION_RULE_ROUTE + id
  );
};

export const updateAutomationMessageTicketDefaulDocById = ({
  item,
  id,
}: {
  item: INewAutomationMessageTicketDefaulDoc;
  id: string;
}) => {
  return GlobalAxios.put(AUTOMATION_RULE_ROUTE + id, item);
};
