import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import { Grid } from "@mui/material";
import { AutoComplete, useFormContext } from "@4uhub/lib4uhub";
import { TForm } from "./Checkboxes";
import { listMainGenerics } from "../../../../../../services/generic.service";

const translationPath = "page.4uSalesContract.registers.salesContractPayer.";

const EntityAddress = (): ReactElement => {
  const { t } = useTranslation();
  const { control } = useFormContext<TForm>();
  const entityType = useWatch({ control, name: "entity", defaultValue: "QPF" });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          {entityType === "QPF" && (
            <AutoComplete
              getOptionLabel={({ name }) => name}
              size="small"
              label={t(translationPath + "address_type")}
              name="addressType"
              request={listMainGenerics}
              params={{ Identifier: "IndividualAddressType" }}
            />
          )}
          {entityType === "QPJ" && (
            <AutoComplete
              getOptionLabel={({ name }) => name}
              size="small"
              label={t(translationPath + "address_type")}
              name="addressType"
              request={listMainGenerics}
              params={{ Identifier: "LegalEntityAddressType" }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EntityAddress;
