import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { ISalesContractProductPayload } from "../models/sales-contract-product";

const SALES_CONTRACT_PRODUCT_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractProduct/";

export const createSalesContractProduct = (
  item: ISalesContractProductPayload
) => {
  return GlobalAxios.post(SALES_CONTRACT_PRODUCT_ROUTE, item);
};

export const listalesContractProduct = () => {
  return GlobalAxios.get<ISelectType[]>(`${SALES_CONTRACT_PRODUCT_ROUTE}list`);
};

export const listSalesContractProductBySalesContract = (params?: any) => {
  return GlobalAxios.get<ISelectType[]>(
    `${SALES_CONTRACT_PRODUCT_ROUTE}bySalesContract`,
    {
      params,
    }
  );
};
