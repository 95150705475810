import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MainApi from "../../../../../../services/mainApi.service";

const SALES_CONTRACT_PRICE_LIST_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractPriceList/";
const salesContractPriceListService = new MainApi<ISelectType>(
  SALES_CONTRACT_PRICE_LIST_ROUTE
);

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

export const SelectSalesContractPriceList = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(salesContractPriceListService.list);

  const { control } = useFormContext();

  const salesContractProduct = useWatch({
    name: "salesContractProduct",
    control,
  });

  const fetchSalesContractPriceList = useCallback(async () => {
    const { data, success } = await sendRequest({
      SalesContractProductId: salesContractProduct
        ? salesContractProduct.id
        : undefined,
    });
    if (data && success) {
      setOptions(data);
    }
  }, [sendRequest, salesContractProduct]);

  useEffect(() => {
    fetchSalesContractPriceList();
  }, [fetchSalesContractPriceList]);

  return (
    <AutoComplete
      name="salesContractPriceList"
      label={t(translationPath + "sales_price_list")}
      getOptionLabel={(option) => option.name}
      size="small"
      fullWidth
      options={options}
    />
  );
});
