import { GlobalAxios } from "../axios/axios-config";

import { IServiceUpdateParam } from "../utils/interfaces";
import { ISaleChannel, ISaleChannelPayload } from "../models/sale-channel";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesChannel";

export const createSaleChannel = (payload: ISaleChannelPayload) => {
  return GlobalAxios.post(serviceURL, payload);
};

export const updateSaleChannel = ({
  id,
  item,
}: IServiceUpdateParam<ISaleChannelPayload>) => {
  return GlobalAxios.put(`${serviceURL}/${id}`, item);
};

export const findSaleChannelById = (id: string) => {
  return GlobalAxios.get<ISaleChannel>(`${serviceURL}/${id}`);
};

export const listSaleChannels = () => {
  return GlobalAxios.get<ISaleChannel[]>(`${serviceURL}/list`);
};
export const removeSaleChannel = (id: string) => {
  return GlobalAxios.delete(`${serviceURL}/${id}`);
};

export const approveSalesChannel = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Approve/${id}`);
};
export const inactivateSalesChannel = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Inactivate/${id}`);
};
export const reactivateSalesChannel = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Reactivate/${id}`);
};
export const suspendSalesChannel = (id: string) => {
  return GlobalAxios.put(`${serviceURL}/Suspend/${id}`);
};
