import { AutoComplete, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { listAllKinshipDegree } from "../../../../../../services/kinshipDegree.service";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

export const SelectKinshipDegree = memo(() => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const salesBeneficiaryHolder = useWatch({
    name: "salesBeneficiaryHolder",
    control,
  });

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        getOptionLabel={({ name }) => name}
        size="small"
        label={t(translationPath + "kinship_degree")}
        name="kinshipDegree"
        request={listAllKinshipDegree}
        disable={!salesBeneficiaryHolder}
      />
    </Grid>
  );
});
