import { useTranslation } from "react-i18next";

import { AutoComplete, useFormContext } from "@4uhub/lib4uhub";

import { buildTPath } from "../../advantages-and-benefits-club.utils";
import { listContractTypes } from "../../../../../../services/salesContractType.service";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { TAdvantagesAndBenefitsClubForm } from "../../advantage-and-benefits-club.schema";
import { useCallback } from "react";

const SalesContractTypeSelect = () => {
  const { t } = useTranslation();

  const translate = (field: string) => t(buildTPath(field));

  const { control, setValue } =
    useFormContext<TAdvantagesAndBenefitsClubForm>();

  const salesContractType = useWatch({
    control,
    name: "salesContractType",
  });
  const valueChangeHandler = useCallback(() => {
    setValue("legalEntityBenefitsAdministrator", null);
  }, [setValue]);

  return (
    <Grid
      item
      xs={12}
      sm={salesContractType && salesContractType.code === "1" ? 6 : 12}
    >
      <AutoComplete
        name="salesContractType"
        size="small"
        label={translate("contract_type")}
        getOptionLabel={({ name }) => name}
        request={listContractTypes}
        onValueChange={valueChangeHandler}
      />
    </Grid>
  );
};

export default SalesContractTypeSelect;
