import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { InputFileForm } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../../../../../../axios/axios-config";
import { IBannerMobileFileValueProps } from "./models";

const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";

const translationPath = "page.register.banners.";
const BannerMobileFileValue: React.FC<IBannerMobileFileValueProps> = ({
  onFileSelected,
}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12}>
      <Typography variant="h6" mb={2}>
        {t(translationPath + "media_file_mobile")}
      </Typography>
      <InputFileForm
        name={`bannerMobileMediaFile`}
        axios={GlobalAxios}
        fileRoute={FILE_ROUTE}
        fileInputProps={{
          accept: ".jpg, ,jpeg, .png, .webp",
          label: `${t("page.register.extensions.file")}`,
          maxFileSize: 2,
          mediaTypeCode: "2",
          mediaTypeIdentifier: "BannerMediaType",
          parentName: "Banner",
          type: "Public",
          onFileSelected: onFileSelected,
          inputProps: {
            size: "small",
          },
        }}
      />
    </Grid>
  );
};

export default BannerMobileFileValue;
