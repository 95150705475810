import { Grid } from "@mui/material";

import {
  FormContainer,
  FormTabs,
  Treturn,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { useCallback, useState } from "react";

import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import MessageIcon from "@mui/icons-material/Message";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ITicketAutomation } from "../../../../../models/ticket-automation-rules";
import {
  createAutomationRule,
  getAutomationRuleById,
  updateAutomationRuleById,
} from "../../../../../services/automarionRule.service";
import { AutomationRulesProvider } from "../../../../../store/contexts/AutomationRulesContext";
import { AutomationMessages } from "./AutomationMessages/AutomationMessages";
import {
  TAutomationRuleForm,
  automationRuleSchema,
} from "./automationRuleSchema";
import { BasicData } from "./BasicData/BasicData";
import { Messages } from "./Messages/Messages";
import { TimeRules } from "./TimeRules/TimeRules";
import { AutomationMessageTicketDefaulDocPage } from "./AutomationMessageTicketDefaulDoc/AutomationMessageTicketDefaulDocPage";

const translationPath = "chatBot.page.automations.automationRules.";

const AutomationRule = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const { eI, id } = useParams();

  const [ticketAutomation, setTicketAutomation] =
    useState<ITicketAutomation | null>(null);

  const { sendRequest: save, loading: sLoading } =
    useFetch(createAutomationRule);

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateAutomationRuleById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(
    getAutomationRuleById
  );

  const saveHandler = (v: TAutomationRuleForm) => {
    return save({
      ...v,
      ticketAutomationId: v.ticketAutomation.id,
      ticketAutomationTemplateId: v.ticketAutomationTemplate.id,
      ticketServiceId: v.ticketService.id,
      ticketEventId: v.ticketEvent ? v.ticketEvent.id : "",
      ticketChannelStepRobotId: v.ticketChannelStepRobot
        ? v.ticketChannelStepRobot.id
        : undefined,
      ticketChannelStepAttendantId: v.ticketChannelStepAttendant
        ? v.ticketChannelStepAttendant.id
        : undefined,
      ticketChannelStepPendentId: v.ticketChannelStepPendent
        ? v.ticketChannelStepPendent.id
        : undefined,
      ticketChannelStepWhenErrorId: v.ticketChannelStepWhenError
        ? v.ticketChannelStepWhenError.id
        : undefined,
      initialDate: v.initialDate?.toISOString().substring(0, 10),
      endDate: v.endDate?.toISOString().substring(0, 10),
    });
  };
  const updateHandler = (v: TAutomationRuleForm, id: string) => {
    return update({
      item: {
        ...v,
        ticketAutomationTemplateId: v.ticketAutomationTemplate.id,
        ticketServiceId: v.ticketService.id,
        ticketEventId: v.ticketEvent ? v.ticketEvent.id : "",
        ticketChannelStepRobotId: v.ticketChannelStepRobot
          ? v.ticketChannelStepRobot.id
          : undefined,
        ticketChannelStepAttendantId: v.ticketChannelStepAttendant
          ? v.ticketChannelStepAttendant.id
          : undefined,
        ticketChannelStepPendentId: v.ticketChannelStepPendent
          ? v.ticketChannelStepPendent.id
          : undefined,
        ticketChannelStepWhenErrorId: v.ticketChannelStepWhenError
          ? v.ticketChannelStepWhenError.id
          : undefined,
        initialDate: v.initialDate?.toISOString().substring(0, 10),
        endDate: v.endDate?.toISOString().substring(0, 10),
      },
      id: id,
    });
  };

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TAutomationRuleForm>> => {
      const { data, success } = await get(id);
      if (data) {
        setTicketAutomation(data.ticketAutomation);

        const newData: TAutomationRuleForm = {
          ...data,
          initialDate: new Date(`${data.initialDate}T12:00:00Z`),
          endDate: new Date(`${data.endDate}T12:00:00Z`),
          ticketAutomation: data.ticketAutomation,
          ticketChannelAttendant: data.ticketChannelStepAttendant
            ? data.ticketChannelStepAttendant.ticketChannel
            : null,
          ticketChannelRobot: data.ticketChannelStepRobot
            ? data.ticketChannelStepRobot.ticketChannel
            : null,
          ticketChannelPendent: data.ticketChannelStepPendent
            ? data.ticketChannelStepPendent.ticketChannel
            : null,
          ticketChannelWhenError: data.ticketChannelStepWhenError
            ? data.ticketChannelStepWhenError.ticketChannel
            : null,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const redirectOnSuccess = useCallback(
    (data?: any) => {
      if (!id && ticketAutomation && ticketAutomation.code !== "8") {
        if (data) {
          setMessage({
            message: t(translationPath + "created_successfully"),
            type: "success",
          });
          navigate(
            `/e/${eI}/chatBot/createAutomations/automationRules/${data.id}/edit`
          );
        }
      } else {
        navigate(`/e/${eI}/chatBot/createAutomations/automationRules/`);
      }
    },
    [navigate, setMessage, t, ticketAutomation, id, eI]
  );

  const loading = sLoading || uLoading || gLoading;

  let tabs = [
    {
      label: t(translationPath + "time_rule"),
      icon: <AccessTimeFilledIcon fontSize="small" />,
      component: <TimeRules />,
    },
    {
      label: t(translationPath + "messages"),
      icon: <SpeakerNotesIcon fontSize="small" />,
      component: <Messages />,
    },
  ];

  if (
    ticketAutomation &&
    ticketAutomation.code !== "2" &&
    ticketAutomation.code !== "3" &&
    ticketAutomation.code !== "6" &&
    ticketAutomation.code !== "7"
  ) {
    tabs.push({
      label: t(translationPath + "automation_message"),
      icon: <MessageIcon fontSize="small" />,
      component: <AutomationMessages />,
    });
  }

  if (
    (ticketAutomation && ticketAutomation.code === "1") ||
    (ticketAutomation && ticketAutomation.code) === "5" ||
    (ticketAutomation && ticketAutomation.code) === "11"
  ) {
    tabs.push({
      label: t(translationPath + "automation_message_ticket_defaul_doc"),
      icon: <ArticleRoundedIcon fontSize="small" />,
      component: <AutomationMessageTicketDefaulDocPage />,
    });
  }
  return (
    <AutomationRulesProvider>
      <>
        <FormContainer<TAutomationRuleForm>
          saveFunction={saveHandler}
          updateFunction={updateHandler}
          getFunction={getHandler}
          loading={loading}
          title={t(translationPath + "automation_rule")}
          schema={automationRuleSchema}
          subtitle={t(translationPath + "new_automation_rule")}
          subtitleWatchField={"name"}
          backUrl={`/e/${eI}/chatBot/createAutomations/automationRules/`}
          redirectOnSave={false}
          onSuccess={redirectOnSuccess}
          formActionsOnBottom={false}
          marginBottom={5}
          customHeight={"fit-content"}
          fields={() => (
            <Grid container spacing={2}>
              <BasicData onGetTicketAutomation={setTicketAutomation} />
            </Grid>
          )}
        />
        {id && ticketAutomation && ticketAutomation.code !== "8" && (
          <Grid container spacing={2} sx={{ width: "80%", margin: "0 auto" }}>
            <Grid item sm={12} mb={5}>
              <FormTabs tabs={tabs} />
            </Grid>
          </Grid>
        )}
      </>
    </AutomationRulesProvider>
  );
};

export default AutomationRule;
