import { memo, useCallback } from "react";
import { ISalesContractPayerFinantialProps } from "./models";
import {
  AutoComplete,
  DateInput,
  FormContainer,
  FormInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { listMainGenerics } from "../../../../../services/generic.service";
import {
  createSalesContractPayerFinantial,
  getSalesContractPayerFinantialById,
  updateSalesContractPayerFinantial,
} from "../../../../../services/salesContractPayerFinantial.service";
import {
  salesContractPayerFinantialSchema,
  TSalesContractPayerFinantialForm,
} from "./salesContractPayerFinantialSchema";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";

const translationPath =
  "page.4uSalesContract.registers.salesContractPayerFinantial.";
export const SalesContractPayerFinantial = memo(
  ({ id, idPayer, onBackButton }: ISalesContractPayerFinantialProps) => {
    const { t } = useTranslation();

    const { onRefresh } = useSalesContract();

    const { sendRequest: save, loading: sLoading } = useFetch(
      createSalesContractPayerFinantial
    );

    const { sendRequest: update, loading: uLoading } = useFetch(
      updateSalesContractPayerFinantial
    );

    const { sendRequest: get, loading: gLoading } = useFetch(
      getSalesContractPayerFinantialById
    );

    const updateHandler = (v: TSalesContractPayerFinantialForm, id: string) =>
      update({
        item: {
          id: id,
          salesContractPayerId: idPayer ?? "",
          initialDate: v.initialDate.toISOString().substring(0, 10),
          endDate: v.endDate.toISOString().substring(0, 10),
          monthlyPaymentDueDate: v.monthlyPaymentDueDate,
          mail: v.mail ? v.mail : undefined,
          phone: v.phone ? v.phone : undefined,
          whatsappNumber: v.whatsappNumber ? v.whatsappNumber : undefined,
          observation: v.observation ? v.observation : undefined,
          bilingMethodId: v.bilingMethod.id,
        },
        id: id,
      });

    const saveHandler = (v: TSalesContractPayerFinantialForm) =>
      save({
        salesContractPayerId: idPayer ?? "",
        initialDate: v.initialDate.toISOString().substring(0, 10),
        endDate: v.endDate.toISOString().substring(0, 10),
        monthlyPaymentDueDate: v.monthlyPaymentDueDate,
        mail: v.mail ? v.mail : undefined,
        phone: v.phone ? v.phone : undefined,
        whatsappNumber: v.whatsappNumber ? v.whatsappNumber : undefined,
        observation: v.observation ? v.observation : undefined,
        bilingMethodId: v.bilingMethod.id,
      });

    const getHandler = useCallback(
      async (
        id: string
      ): Promise<Treturn<TSalesContractPayerFinantialForm>> => {
        const { data, success } = await get(id);

        if (data) {
          const newData: TSalesContractPayerFinantialForm = {
            ...data,
            initialDate: new Date(`${data.initialDate}T00:00:00`),
            endDate: new Date(`${data.endDate}T00:00:00`),
            monthlyPaymentDueDate: data.monthlyPaymentDueDate.toString(),
            mail: data.mail,
            phone: data.phone,
            whatsappNumber: data.whatsappNumber,
            observation: data.observation,
            bilingMethod: data.bilingMethod,
          };
          return { data: newData, success: true };
        }
        return { data, success };
      },
      [get]
    );
    const successHandle = () => {
      onBackButton();
      onRefresh();
    };
    const loading = sLoading || uLoading || gLoading;

    return (
      <FormContainer<TSalesContractPayerFinantialForm>
        saveFunction={saveHandler}
        updateFunction={updateHandler}
        getFunction={getHandler}
        loading={loading}
        title={t(translationPath + "title")}
        schema={salesContractPayerFinantialSchema}
        subtitle={t(translationPath + "new")}
        backButtonOnClick={onBackButton}
        redirectOnSave={false}
        onSuccess={successHandle}
        isModalForm={true}
        propId={id}
        formActionsOnBottom={false}
        fields={() => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <AutoComplete
                getOptionLabel={({ name }) => name}
                size="small"
                label={t(translationPath + "biling_method")}
                name="bilingMethod"
                request={listMainGenerics}
                params={{
                  Identifier: "SalesContractPayerFinantialsBillingMethod",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                size="small"
                name="mail"
                fullWidth
                label={t(translationPath + "mail") || "Mail"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                name="phone"
                type="number"
                label={t(translationPath + "phone") || "Phone"}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                name="whatsappNumber"
                label={
                  t(translationPath + "whatsapp_number") || "Whatsapp Number"
                }
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateInput
                name={"initialDate"}
                label={t(translationPath + "initial_date") || "Initial Date"}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateInput
                name={"endDate"}
                label={t(translationPath + "end_date") || "End Date"}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInput
                size="small"
                type="number"
                name="monthlyPaymentDueDate"
                fullWidth
                label={
                  t(translationPath + "monthly_payment_due_date") ||
                  "Monthly Payment Due Date"
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormInput
                multiline
                name="observation"
                label={t(translationPath + "observation") || "Observation"}
                size="small"
                fullWidth
                minRows={4}
              />
            </Grid>
          </Grid>
        )}
      />
    );
  }
);
