import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  DateInput,
  FormContainer,
  FormInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";
import { ISalesContractBeneficiaryCardProps } from "./models";
import {
  createSalesContractBeneficiaryCard,
  getSalesContractBeneficiaryCardById,
  updateSalesContractBeneficiaryCard,
} from "../../../../../services/salesContractBeneficiaryCard.service";
import {
  salesContractBeneficiaryCardSchema,
  TSalesContractBeneficiaryCardForm,
} from "./salesContractBeneficiaryCardSchema";

const translationPath =
  "page.4uSalesContract.registers.salesContractBeneficiaryCard.";

export const SalesContractBeneficiaryCard = memo(
  ({ id, idBeneficiary, onBackButton }: ISalesContractBeneficiaryCardProps) => {
    const { t } = useTranslation();

    const { onRefresh } = useSalesContract();

    const { sendRequest: save, loading: sLoading } = useFetch(
      createSalesContractBeneficiaryCard
    );

    const { sendRequest: update, loading: uLoading } = useFetch(
      updateSalesContractBeneficiaryCard
    );

    const { sendRequest: get, loading: gLoading } = useFetch(
      getSalesContractBeneficiaryCardById
    );

    const updateHandler = (
      v: TSalesContractBeneficiaryCardForm,
      id: string
    ) => {
      return update({
        item: {
          id: id,
          integrationId: v.integrationId ? v.integrationId : undefined,
          cardIdentifier: v.cardIdentifier,
          initialDate: v.initialDate.toISOString().substring(0, 10),
          endDate: v.endDate.toISOString().substring(0, 10),
          salesContractBeneficiaryId: idBeneficiary ?? "",
        },
        id: id,
      });
    };

    const saveHandler = (v: TSalesContractBeneficiaryCardForm) =>
      save({
        integrationId: v.integrationId ? v.integrationId : undefined,
        cardIdentifier: v.cardIdentifier,
        initialDate: v.initialDate.toISOString().substring(0, 10),
        endDate: v.endDate.toISOString().substring(0, 10),
        salesContractBeneficiaryId: idBeneficiary ?? "",
      });

    const getHandler = useCallback(
      async (
        id: string
      ): Promise<Treturn<TSalesContractBeneficiaryCardForm>> => {
        const { data, success } = await get(id);

        if (data) {
          const newData: TSalesContractBeneficiaryCardForm = {
            ...data,
            integrationId: data.integrationId,
            cardIdentifier: data.cardIdentifier,
            initialDate: new Date(`${data.initialDate}T00:00:00`),
            endDate: new Date(`${data.endDate}T00:00:00`),
          };
          return { data: newData, success: true };
        }
        return { data, success };
      },
      [get]
    );

    const successHandle = () => {
      onBackButton();
      onRefresh();
    };

    const loading = sLoading || uLoading || gLoading;

    return (
      <FormContainer<TSalesContractBeneficiaryCardForm>
        saveFunction={saveHandler}
        updateFunction={updateHandler}
        getFunction={getHandler}
        loading={loading}
        title={t(translationPath + "title")}
        schema={salesContractBeneficiaryCardSchema}
        subtitle={t(translationPath + "add")}
        subtitleWatchField={"cardIdentifier"}
        showBackButton={true}
        onSuccess={successHandle}
        redirectOnSave={false}
        propId={id || undefined}
        backButtonOnClick={onBackButton}
        isModalForm={true}
        marginBottom={0}
        customHeight={"fit-content"}
        formActionsOnBottom={false}
        fields={() => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInput
                size="small"
                name="cardIdentifier"
                fullWidth
                label={
                  t(translationPath + "card_identifier") || "Card Identifier"
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                name="integrationId"
                label={
                  t(translationPath + "integration_code") || "Integration Code"
                }
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateInput
                name={"initialDate"}
                label={t(translationPath + "initial_date") || "Initial Date"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateInput
                name={"endDate"}
                label={t(translationPath + "end_date") || "End Date"}
              />
            </Grid>
          </Grid>
        )}
      />
    );
  }
);
