import { GlobalAxios } from "./../axios/axios-config";
import {
  IProtocolDefault,
  IProtocolsDefault,
  IUpdateProtocolDefault,
} from "../models/protocols-default";
import { ISelectType } from "@4uhub/lib4uhub";

const PROTOCOL_DEFAULT_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketDefaultDoc";

export const createProtocolDefault = (item: IProtocolDefault) => {
  return GlobalAxios.post(PROTOCOL_DEFAULT_ROUTE, item);
};

export const getProtocolDefaultById = (id: string) => {
  return GlobalAxios.get<IProtocolsDefault>(PROTOCOL_DEFAULT_ROUTE + "/" + id);
};

export const updateProtocolDefaultById = ({
  item,
  id,
}: {
  item: IUpdateProtocolDefault;
  id: string;
}) => {
  return GlobalAxios.put(PROTOCOL_DEFAULT_ROUTE + "/" + id, item);
};

export const listProtocolDefault = () => {
  return GlobalAxios.get<ISelectType[]>(`${PROTOCOL_DEFAULT_ROUTE}/list`);
};
