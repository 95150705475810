import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import {
  AutoComplete,
  FormContainer,
  FormInput,
  IInputFile,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";

import MainApiService from "../../../../../services/mainApi.service";
import {
  createBanner,
  getBannerById,
  updateBannerById,
} from "../../../../../services/banners.service";
import { TBannerForm, bannerSchema } from "./BannerSchema";
import BannersApps from "./BannersApps";
import BannersLocals from "./BannersLocals/BannersLocals";
import BannersPreview from "./BannersPreview/BannersPreview";
import BannerMobileFile from "./BannerMobileFile/BannerMobileFile";
import BannerFile from "./BannerFile/BannerFile";

const LANGUAGE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Language/";
const languageService = new MainApiService<ISelectType>(LANGUAGE_ROUTE);

const translationPath = "page.register.banners.";

const Banner = () => {
  const { t } = useTranslation();
  const [bannersFilesId, setBannersFilesId] = useState<string[]>();
  let deleteFilesList: string[] = [];

  const onDeleFileHandler = (file: IInputFile) => {
    const selectedFile = file;
    if (selectedFile.id.length !== 0) {
      deleteFilesList.push(selectedFile.id);
    }
  };

  const { sendRequest: save, loading: sLoading } = useFetch(createBanner);

  const { sendRequest: update, loading: uLoading } = useFetch(updateBannerById);

  const { sendRequest: get, loading: gLoading } = useFetch(getBannerById);

  const handleSave = (v: TBannerForm) => {
    const allMediaFiles = [];

    if (v.bannerMobileMediaFile) {
      allMediaFiles.push({ file: v.bannerMobileMediaFile });
    }
    if (v.bannerMediaFile) {
      allMediaFiles.push({ file: v.bannerMediaFile });
    }
    const saveFilesIds = allMediaFiles.filter((media) =>
      media.file ? !bannersFilesId?.includes(media.file.id!) : ""
    );

    return save({
      alternativeText: v.alternativeText,
      enable: v.enable,
      name: v.name,
      title: v.title,
      bannerLocals: v.bannerLocals.map((local) => local.id),
      siteApps: v.siteApps.map((siteApp) => siteApp.id),
      mediaFilesIds: saveFilesIds.map((media) =>
        media.file ? media.file.id! : ""
      ),
      languageId: v.language.id,
      displayOrder: +v.displayOrder,
      deleteMediaFilesIds: deleteFilesList,
    });
  };

  const updateHandler = (v: TBannerForm, id: string) => {
    const allMediaFiles = [];
    if (v.bannerMobileMediaFile) {
      allMediaFiles.push({ file: v.bannerMobileMediaFile });
    }
    if (v.bannerMediaFile) {
      allMediaFiles.push({ file: v.bannerMediaFile });
    }
    const updateFilesIds = allMediaFiles.filter((media) =>
      media.file ? !bannersFilesId?.includes(media.file.id!) : ""
    );
    return update({
      item: {
        ...v,
        alternativeText: v.alternativeText,
        enable: v.enable,
        name: v.name,
        title: v.title,
        bannerLocals: v.bannerLocals.map((local) => local.id),
        siteApps: v.siteApps.map((siteApp) => siteApp.id),
        mediaFilesIds: updateFilesIds.map((media) =>
          media.file ? media.file.id! : ""
        ),
        languageId: v.language.id,
        displayOrder: +v.displayOrder,
        deleteMediaFilesIds: deleteFilesList,
      },
      id: id,
    });
  };

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TBannerForm>> => {
      const { data, success } = await get(id);

      if (success && data) {
        const filesId = data.bannerMediaFiles
          ? data.bannerMediaFiles.map((media) => media.id || "")
          : [];
        if (filesId) setBannersFilesId(filesId);

        const bannerMobile = data.bannerMediaFiles.find(
          (media) => media.mediaType?.code === "2"
        );

        const bannerFile = data.bannerMediaFiles.find(
          (media) => media.mediaType?.code === "1"
        );

        return {
          data: {
            ...data,
            bannerMediaFile: bannerFile
              ? {
                  id: bannerFile.id,
                  cdnDomain: bannerFile.cdnDomain,
                  fileName: bannerFile.fileName,
                  filePath: bannerFile.filePath,
                  fileSize: bannerFile.fileSize,
                  isPublic: bannerFile.isPublic,
                  mediaType: bannerFile.mediaType,
                  mediaTypeId: bannerFile.mediaTypeId,
                  mimeType: bannerFile.mimeType,
                }
              : null,
            bannerMobileMediaFile: bannerMobile
              ? {
                  id: bannerMobile.id,
                  cdnDomain: bannerMobile.cdnDomain,
                  fileName: bannerMobile.fileName,
                  filePath: bannerMobile.filePath,
                  fileSize: bannerMobile.fileSize,
                  isPublic: bannerMobile.isPublic,
                  mediaType: bannerMobile.mediaType,
                  mediaTypeId: bannerMobile.mediaTypeId,
                  mimeType: bannerMobile.mimeType,
                }
              : null,

            displayOrder: data.displayOrder ? data.displayOrder.toString() : "",
          },
          success: true,
        };
      }
      return {
        data: null,
        success: false,
      };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TBannerForm>
      saveFunction={handleSave}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "banner")}
      schema={bannerSchema}
      subtitle={t(translationPath + "new_banner")}
      subtitleWatchField={"name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="name"
              fullWidth
              label={t(translationPath + "name") || "Name"}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="title"
              fullWidth
              label={t(translationPath + "title") || "Title"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="alternativeText"
              fullWidth
              label={
                t(translationPath + "alternative_text") || "Alternative text"
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormInput
              size="small"
              name="displayOrder"
              fullWidth
              label={t(translationPath + "display_order") || "Display order"}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              label={t(translationPath + "language")}
              name="language"
              request={languageService.list}
            />
          </Grid>

          <BannersApps />

          <BannersLocals />

          <Grid item xs={12}>
            <FormInput
              size="small"
              name="targetingLink"
              fullWidth
              label={
                t(translationPath + "targetingLink") || "targetingLink order"
              }
            />
          </Grid>

          <BannerFile onFileSelected={onDeleFileHandler} />
          <BannerMobileFile onFileSelected={onDeleFileHandler} />

          <BannersPreview />
        </Grid>
      )}
    />
  );
};

export default Banner;
