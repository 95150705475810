import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  DateInput,
  FormContainer,
  FormInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";
import {
  TSalesContractPriceListForm,
  salesContractPriceListSchema,
} from "./salesContractPriceListSchema";
import {
  createSalesContractPriceList,
  getSalesContractPriceListById,
  updateSalesContractPriceListById,
} from "../../../../../services/salesContractPriceList.service";
import { SalesContractPriceListItems } from "./SalesContractPriceListItems/SalesContractPriceListItems";
import { ISalesContractPriceListProps } from "./models";
import { useSalesContract } from "../../../../../store/contexts/SalesContractContext";

const translationPath =
  "page.4uSalesContract.registers.salesContractPriceList.";

export const SalesContractPriceList = memo(
  ({ id, idProduct, onBackButton }: ISalesContractPriceListProps) => {
    const { t } = useTranslation();

    const { onRefresh } = useSalesContract();

    const { sendRequest: save, loading: sLoading } = useFetch(
      createSalesContractPriceList
    );

    const { sendRequest: update, loading: uLoading } = useFetch(
      updateSalesContractPriceListById
    );

    const { sendRequest: get, loading: gLoading } = useFetch(
      getSalesContractPriceListById
    );

    const updateHandler = (v: TSalesContractPriceListForm, id: string) => {
      return update({
        item: {
          id: id,
          name: v.name,
          code: v.code,
          initialDate: v.initialDate.toISOString().substring(0, 10),
          endDate: v.endDate.toISOString().substring(0, 10),
          salesContractProductId: idProduct ?? "",
          salesContractPriceListItems: v.salesContractPriceListItems
            ? v.salesContractPriceListItems.map((listItem) => ({
                price: parseFloat(listItem.price),
                degreeOfEntitlementId: listItem.degreeOfEntitlement
                  ? listItem.degreeOfEntitlement.id
                  : null,
                initalAge: listItem.initalAge
                  ? parseInt(listItem.initalAge)
                  : null,
                endAge: listItem.endAge ? parseInt(listItem.endAge) : null,
              }))
            : null,
        },
        id: id,
      });
    };

    const saveHandler = (v: TSalesContractPriceListForm) =>
      save({
        name: v.name,
        code: v.code,
        initialDate: v.initialDate.toISOString().substring(0, 10),
        endDate: v.endDate.toISOString().substring(0, 10),
        salesContractProductId: idProduct ?? "",
        salesContractPriceListItems: v.salesContractPriceListItems.map(
          (listItem) => ({
            price: parseFloat(listItem.price),
            degreeOfEntitlementId: listItem.degreeOfEntitlement
              ? listItem.degreeOfEntitlement.id
              : null,
            initalAge: listItem.initalAge ? parseInt(listItem.initalAge) : null,
            endAge: listItem.endAge ? parseInt(listItem.endAge) : null,
          })
        ),
      });

    const getHandler = useCallback(
      async (id: string): Promise<Treturn<TSalesContractPriceListForm>> => {
        const { data, success } = await get(id);

        if (data) {
          const newData: TSalesContractPriceListForm = {
            ...data,
            name: data.name,
            code: data.code,
            initialDate: new Date(`${data.initialDate}T00:00:00`),
            endDate: new Date(`${data.endDate}T00:00:00`),
            salesContractPriceListItems: data.salesContractPriceListItems.map(
              (listItem) => ({
                ...listItem,
                endAge: listItem.endAge ? listItem.endAge.toString() : "",
                initalAge: listItem.initalAge
                  ? listItem.initalAge.toString()
                  : "",
                price: listItem.price ? listItem.price.toString() : "",
              })
            ),
          };
          return { data: newData, success: true };
        }
        return { data, success };
      },
      [get]
    );

    const successHandle = () => {
      onBackButton();
      onRefresh();
    };

    const loading = sLoading || uLoading || gLoading;

    return (
      <FormContainer<TSalesContractPriceListForm>
        saveFunction={saveHandler}
        updateFunction={updateHandler}
        getFunction={getHandler}
        loading={loading}
        title={t(translationPath + "price_list")}
        schema={salesContractPriceListSchema}
        subtitle={t(translationPath + "new_price_list")}
        subtitleWatchField={"name"}
        showBackButton={true}
        onSuccess={successHandle}
        redirectOnSave={false}
        propId={id || undefined}
        backButtonOnClick={onBackButton}
        isModalForm={true}
        marginBottom={0}
        customHeight={"fit-content"}
        formActionsOnBottom={false}
        fields={() => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInput
                size="small"
                name="name"
                fullWidth
                label={t(translationPath + "name") || "Name"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                name="code"
                label={t(translationPath + "code") || "Code"}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateInput
                name={"initialDate"}
                label={t(translationPath + "initial_date") || "Initial Date"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateInput
                name={"endDate"}
                label={t(translationPath + "end_date") || "End Date"}
              />
            </Grid>

            <SalesContractPriceListItems />
          </Grid>
        )}
      />
    );
  }
);
