import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";

import { buildTPath } from "../../advantages-and-benefits-club.utils";
import { useWatch } from "react-hook-form";
import MainApi from "../../../../../../services/mainApi.service";

interface IOption extends ISelectType {
  contractTitle: string;
}

const SALES_CONTRACT_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContract/";
const salesContractService = new MainApi<IOption>(SALES_CONTRACT_ROUTE);
const MainContractSelect = () => {
  const [options, setOptions] = useState<IOption[]>([]);
  const { t } = useTranslation();

  const { control } = useFormContext();

  const { sendRequest } = useFetch(salesContractService.list);

  const legalEntityStipulant = useWatch({
    name: "legalEntityStipulant",
    control,
  });

  const individualStipulant = useWatch({
    name: "individualStipulant",
    control,
  });

  const translate = (field: string) => t(buildTPath(field));

  const getOptions = useCallback(async () => {
    const { data, success } = await sendRequest({
      LegalEntityStipulantId: legalEntityStipulant
        ? legalEntityStipulant.id
        : null,
      IndividualStipulantId: individualStipulant
        ? individualStipulant.id
        : null,
    });
    if (data && success) {
      const response = (data as IOption[])?.map((item) => ({
        ...item,
        name: item?.contractTitle || "",
      }));
      setOptions(response);
    }
  }, [sendRequest, legalEntityStipulant, individualStipulant]);

  useEffect(() => {
    if (!legalEntityStipulant && !individualStipulant?.id) return;
    getOptions();
  }, [getOptions, legalEntityStipulant, individualStipulant]);

  const isDisabled =
    (!legalEntityStipulant?.id && !individualStipulant?.id) ||
    options.length === 0;
  return (
    <AutoComplete
      name="mainContract"
      size="small"
      label={translate("main_contract")}
      getOptionLabel={({ name }) => name}
      options={options}
      disable={isDisabled}
    />
  );
};

export default MainContractSelect;
