import {
  AutoCompleteAutomations,
  ITicketAutomation,
  Modal,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Box, Button, Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GlobalAxios } from "../../../../../axios/axios-config";
import FormInformation from "../../../../../components/FormInformation/FormInformation";
import { BUTTONS_OPTIONS } from "../../../../../components/WhatsappTemplate/Buttons/SelectButtons/SelectButtons";
import { useTemplateWhats } from "../../../../../store/contexts/TemplateWhatsContext";
import { TWhatsappForm } from "./wathsappSchema";

const translationPath = "chatBot.page.templates.whatsapp.";

export const WhatsappTemplateAutomation = memo(() => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const params = useMemo(() => {
    return { AllowWhatsappTemplate: true };
  }, []);

  const [oldAutomation, setOldAutomation] = useState<ITicketAutomation | null>(
    null
  );

  const [newAutomation, setNewAutomation] = useState<ITicketAutomation | null>(
    null
  );

  const { id } = useParams();

  const { getValues, setValue, reset, resetField } =
    useFormContext<TWhatsappForm>();

  const {
    fixDataHandler,
    setFixedCustomButton,
    setFixedCtaButton,
    removeFixedCustomButton,
    deleteCtaHandler,
    updateBasicData,
  } = useTemplateWhats();

  const removeFixedCustomButtons = useCallback(() => {
    removeFixedCustomButton(0);
    removeFixedCustomButton(1);
  }, [removeFixedCustomButton]);

  const addFixedCustomButtons = useCallback(() => {
    removeFixedCustomButtons();
    const customButtons = [
      { id: "1", text: "Sim", order: 1 },
      { id: "2", text: "Não", order: 2 },
    ];
    setValue("ctaButtons", []);
    deleteCtaHandler("1", 0);
    setFixedCustomButton(customButtons[0], 0);
    setFixedCustomButton(customButtons[1], 1);
    fixDataHandler(true);
  }, [
    fixDataHandler,
    setValue,
    removeFixedCustomButtons,
    setFixedCustomButton,
    deleteCtaHandler,
  ]);

  const valueChangeHandler = useCallback(
    (value: ITicketAutomation | null) => {
      const newCtaButton = {
        id: "1",
        actionType: BUTTONS_OPTIONS.find((btn) => btn.id === "2")!,
        order: 1,
        text: "Clique e responda",
        payload: "",
      };

      switch (value?.code) {
        case "1":
          addFixedCustomButtons();
          break;
        case "2":
        case "6":
          fixDataHandler(true);
          break;
        case "3":
          setFixedCtaButton(newCtaButton, 0);
          setValue("customButtons", []);
          removeFixedCustomButtons();
          setValue(`ctaButtons.${0}.urlType`, {
            id: "2",
            name: t("components.accessSiteAction.dynamic"),
          });
          fixDataHandler(true);
          break;
        case "4":
        case "5":
        case "9":
        case "10":
        case "11":
          addFixedCustomButtons();
          break;
        case "8":
          removeFixedCustomButtons();
          setValue("customButtons", []);
          setValue("ctaButtons", []);
          deleteCtaHandler("1", 0);
          fixDataHandler(true);
          break;
        default:
          removeFixedCustomButtons();
          fixDataHandler(false);
          break;
      }

      if (oldAutomation) {
        setOpen(true);
        setValue("ticketAutomation", oldAutomation);
      } else {
        setOldAutomation(value);
      }
      setNewAutomation(value);
    },
    [
      fixDataHandler,
      setFixedCtaButton,
      addFixedCustomButtons,
      removeFixedCustomButtons,
      deleteCtaHandler,
      setValue,
      t,
      oldAutomation,
    ]
  );

  const closeHandler = useCallback(() => {
    valueChangeHandler(oldAutomation);
    setOpen(false);
  }, [oldAutomation, valueChangeHandler]);

  const resetFormHandler = useCallback(() => {
    if (newAutomation) {
      setValue("ticketAutomation", newAutomation);
      setValue("body", "");
      setValue("footer", "");
      setValue("title", "");
      setValue("name", "");
      resetField("ticketService");
      setValue("bodyExamples", []);
      updateBasicData("body", "");
    } else {
      reset({ ticketAutomation: undefined });
    }
    setOldAutomation(newAutomation);
    setOpen(false);
  }, [reset, resetField, setValue, updateBasicData, newAutomation]);

  useEffect(() => {
    if (!oldAutomation) {
      const automation = getValues("ticketAutomation");
      if (
        automation &&
        (automation.code === "1" ||
          automation.code === "2" ||
          automation.code === "3" ||
          automation.code === "5" ||
          automation.code === "8")
      ) {
        fixDataHandler(true);
      }
    }
  }, [oldAutomation, getValues, fixDataHandler]);

  if (id) {
    return (
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "automation")}
          data={getValues("ticketAutomation").name}
          minHeight={40}
        />
      </Grid>
    );
  }

  return (
    <>
      <Modal
        open={open}
        onClose={closeHandler}
        title={`${t(translationPath + "whatsappTemplateAutomation.title")}`}
      >
        <Typography>
          {t(translationPath + "whatsappTemplateAutomation.description")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            onClick={closeHandler}
          >
            {t(translationPath + "whatsappTemplateAutomation.cancel")}
          </Button>
          <Button
            size="small"
            fullWidth
            variant="contained"
            onClick={resetFormHandler}
          >
            {t(translationPath + "whatsappTemplateAutomation.change")}
          </Button>
        </Box>
      </Modal>
      <Grid item xs={12} sm={6}>
        <AutoCompleteAutomations
          params={params}
          name="ticketAutomation"
          label={t(translationPath + "automation")}
          getOptionLabel={(option) => option.name}
          onValueChange={valueChangeHandler}
          size="small"
          axios={GlobalAxios}
          fullWidth
        />
      </Grid>
    </>
  );
});
