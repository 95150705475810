import { AutoComplete, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { listContractProducts } from "../../../../../../services/salesProductCtr.service";
import { TSalesContractProductForm } from "../salesContractProductSchema";

const translationPath = "page.4uSalesContract.registers.salesContractProducts.";

export const SelectSalesProduct = memo(() => {
  const { t } = useTranslation();

  const { resetField } = useFormContext<TSalesContractProductForm>();

  const changeHandler = useCallback(() => {
    resetField("salesProductCtrPriceList", { defaultValue: null });
  }, [resetField]);

  return (
    <Grid item xs={12} sm={12}>
      <AutoComplete
        getOptionLabel={({ name }) => name}
        size="small"
        label={t(translationPath + "product")}
        name="salesProductCtr"
        request={listContractProducts}
        onValueChange={changeHandler}
      />
    </Grid>
  );
});
