import {
  AutoComplete,
  DateInput,
  FormInput,
  IInputFile,
  InputFileForm,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { listContractAttachmentType } from "../../../../../services/contractAttachmentType.service";
import { GlobalAxios } from "../../../../../axios/axios-config";

const translationPath =
  "page.4uSalesContract.registers.salesContractAttachments.";
const FILE_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/Upload";

export const SalesContractAttachmentForm = memo(
  ({
    id,
    onDeleteFile,
  }: {
    id?: string;
    onDeleteFile: (file: IInputFile) => void;
  }) => {
    const { t } = useTranslation();

    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormInput
            size="small"
            name="title"
            fullWidth
            label={t(translationPath + "title_form") || "Title"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateInput
            name={"attachmentDate"}
            label={t(translationPath + "attachment_date") || "Attachment Date"}
            initialDate={new Date()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoComplete
            getOptionLabel={({ name }) => name}
            size="small"
            label={t(translationPath + "attachment_type")}
            name="salesContractAttachmentType"
            request={listContractAttachmentType}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputFileForm
            name={"mediaFile"}
            axios={GlobalAxios}
            disable={!id ? false : true}
            fileRoute={FILE_ROUTE}
            fileInputProps={{
              accept: ".jpg, ,jpeg, .png, .webp",
              label: `${t("page.register.extensions.file")}`,
              maxFileSize: 2,
              mediaTypeCode: "1",
              mediaTypeIdentifier: "SalesContractAttachmentMediaType",
              parentName: "SalesContractAttachment",
              type: "Public",
              onFileSelected: onDeleteFile,
              inputProps: {
                size: "small",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInput
            size="small"
            multiline
            name="description"
            fullWidth
            minRows={4}
            label={t(translationPath + "description") || "Description"}
          />
        </Grid>
      </>
    );
  }
);
