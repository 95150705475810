import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Table } from "@4uhub/lib4uhub";
import { Chip } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";

import { IChannelClassification } from "../../../../models/channel-classification";
import {
  buildTPath,
  channelClassificationsService,
} from "./channel-classifications.utils";

const ChannelClassificationsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IChannelClassification>[] = [
    {
      field: "code",
      headerName: t(buildTPath("code"))!,
    },
    {
      field: "name",
      headerName: t(buildTPath("name"))!,
    },
    {
      field: "enable",
      headerName: t(buildTPath("status"))!,
      renderCell: ({ value }) => (
        <Chip
          size="small"
          color={value ? "success" : "error"}
          label={value ? t(buildTPath("active")) : t(buildTPath("inactive"))}
        />
      ),
    },
  ];

  const handleRowClick = ({ id }: GridRowParams<IChannelClassification>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IChannelClassification>
      showDefaultMacroFilters={false}
      service={channelClassificationsService}
      columns={columns}
      title={t(buildTPath("title"))}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={t(buildTPath("search"))}
      addButtonLabel={t(buildTPath("add"))!}
      confirmOptions={{
        title: t(buildTPath("confirm.title")),
        description: t(buildTPath("confirm.description")),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ChannelClassificationsPage;
